

function Dictionary() {
    return (
        <div>
        <h1>Dictionary</h1>
        <p>Dictionary Page</p>
        </div>
    );
}

export default Dictionary;