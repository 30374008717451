import { 
    Link,
} from "react-router-dom";

import LogoWCC from "assets/logo-wcclp-inverted.png";
import LogoILA from "assets/logo-ila.png";

function Acknowledgement() {
    return (
        <div className="mx-4 md:mx-auto md:max-w-2xl">

        <img src={LogoWCC} height={80} width={80} alt="WCC Logo mark"/>
        <p className="my-10 font-bold text-gray-400">Lotjpa Yapaneyepuk (speak together)</p>

        <p className="text-center text-gray-400">Language content spoken by</p>
        <p className="mb-10 text-center">Roxanne Atkinson</p>


        <p className="mb-4 text-gray-400">All information in this application is based on the resource Lotjpa Yapaneyepuk (speak together) compiled by Roxanne Atkinson. Audio recorded on Yorta Yorta country remotely during the 2021 pandemic.</p>

        <p className="mb-4 text-gray-400">We acknowledge the support and guidance of Yorta Yorta elders (both past and present) in maintaining Traditional Values, Language and Culture.</p>

        <p className="mb-4 text-gray-400">Resource created by WCC Language Program with support from the Australian Government's Indigenous Languages and Arts program.</p>

        <img src={LogoILA} width={350} height={92} className="mx-auto"/>
        <p  className="mb-4 text-gray-400">Supported through the Australia Government's Indigenous Language and Arts program.</p>



        <Link to="menu" className="block w-full py-4 mt-10 font-bold text-center rounded-lg bg-accent-yy">
            Continue
        </Link>
        </div>
    )
}

export default Acknowledgement;