import {
    useEffect,
    useState
} from "react";

import {
    useParams,
    useNavigate
} from "react-router-dom";

import Wiradjuri from "data/Wiradjuri.json";


function WordDetail() {

    const { wordId } = useParams();

    const words = Wiradjuri.categories
        .map(category => category.entries)
        .flat(1)
        .filter((word: any) => word.id === wordId);

    const [isFavourite, setIsFavorite] = useState(false);

    useEffect(() => {
        if (wordId) {
            setIsFavorite(localStorage.getItem(wordId) === "true");
        }
    }, []);

    const word = words[0];

    const [audioPlaying, setAudioPlaying] = useState(false);

    const toggleAudio = () => {
        const audio = document.getElementById("audio-player") as HTMLAudioElement;
        if (audio.paused) {
            audio.play();
            setAudioPlaying(true);
        } else {
            audio.pause();
            setAudioPlaying(false);
        }
    }

    const toggleFavourite = () => {
        if (wordId) {
            if (localStorage.getItem(wordId) === "true") {
                localStorage.removeItem(wordId);
                setIsFavorite(false);
            }
            else {
                localStorage.setItem(wordId, "true");
                setIsFavorite(true);
            }
        }
    }

    return (
        <div className="mx-4 md:max-w-2xl md:mx-auto">
            {word.image !== null && (
                <img
                    alt={word.english}
                    className="rounded-lg"
                    src={`https://media.guyulgang.com/wiradjuri/20220620/images/${word.image}`} />
            )}
            <p className="py-6 text-gray-400 uppercase">English</p>
            <p className="text-4xl text-accent-wiradjuri">{word.english}</p>
            <p className="py-6 text-gray-400 uppercase">Wiradjuri</p>
            <p className="text-4xl">{word.translation}</p>
            <div className="grid grid-cols-2 gap-6 py-10">
                <p>Word Type</p>
                <p className="text-right">
                    {word.word_type !== "" ? word.word_type : "Not Available"}
                </p>
                <p>Category</p>
                <p className="text-right">
                    Not Available
                </p>
            </div>
            {word.audio !== null && (<>
                <audio id="audio-player" src={`https://media.guyulgang.com/wiradjuri/20220620/mp3/${word.audio}`} onEnded={() => { setAudioPlaying(false); }}>
                    <a href={`https://media.guyulgang.com/wiradjuri/20220620/mp3/${word.audio}`}>
                        Download the audio file
                    </a>
                </audio>
                <button className="flex items-center justify-between w-full my-8 text-2xl text-accent-wiradjuri" onClick={() => toggleAudio()}>
                    <span>Listen</span>
                    {!audioPlaying && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z" clipRule="evenodd" />
                        </svg>)}
                    {audioPlaying && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM9 8.25a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75H9zm5.25 0a.75.75 0 00-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75h-.75z" clipRule="evenodd" />
                        </svg>)}

                </button>
            </>)}
            <button className="flex items-center justify-between w-full my-8 text-2xl text-accent-wiradjuri" onClick={() => toggleFavourite()}>
                <span>{isFavourite ? "Remove from favourites" : "Add to favourites"}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
                    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clipRule="evenodd" />
                </svg>
            </button>
        </div>
    )
}

export default WordDetail;