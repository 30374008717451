import {
    Link
} from "react-router-dom";

import Wiradjuri from "data/Wiradjuri.json";

function Categories() {

    /**
     * Sorts the categories alphabetically
     */
    const alphabeticCategories = Wiradjuri.categories
        .sort((a: any, b: any) => a.name.localeCompare(b.name));

    return (
        <>
            <h1 className="p-4 mx-auto mb-4 text-2xl font-bold md:max-w-2xl md:p-0">
                Categories
            </h1>
            <ul className="grid max-w-md grid-cols-1 mx-auto mb-6 divide-y-2 divide-gray-700 md:max-w-2xl">
                <li key="all" className="p-4">
                    <Link to="../all" className="flex flex-col justify-between">
                        <h2 className="text-xl font-bold rounded-full text-accent-wiradjuri">
                            All words
                        </h2>
                        <span className="mt-2">
                        </span>
                    </Link>
                </li>

                {alphabeticCategories.map((category: any) => (
                    <li key={category.id} className="p-4">
                        <Link to={`../category/${category.id}/words`} className="flex flex-col justify-between">
                            <h2 className="text-xl font-bold rounded-full text-accent-wiradjuri">
                                {category.name}
                            </h2>
                            <span className="mt-2">
                                {category.entries.length} words
                            </span>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Categories;