/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * guyulgang-api
 * 
        This project provides a reference Python API built using FastAPI, the 
        aim of the project is:

        - To maintain a good know source of habits
        - Demonstrate how applications are meant to be put together at Anomaly
        - Democratize design of robust API    
    
 * OpenAPI spec version: 0.16.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  UserResponse,
  HTTPValidationError,
  GetUsersWithLimitsParams,
  UserCreateRequest,
  GetUsersParams,
  UserDetailResponse,
  UserRequest
} from '.././models'


/**
 * @summary Query users between limits
 */
export const getUsersWithLimits = (
    params?: GetUsersWithLimitsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserResponse[]>> => {
    return axios.get(
      `/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetUsersWithLimitsQueryKey = (params?: GetUsersWithLimitsParams,) => [`/users`, ...(params ? [params]: [])] as const;
  

    
export const getGetUsersWithLimitsQueryOptions = <TData = Awaited<ReturnType<typeof getUsersWithLimits>>, TError = AxiosError<HTTPValidationError>>(params?: GetUsersWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsersWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getUsersWithLimits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersWithLimitsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersWithLimits>>> = ({ signal }) => getUsersWithLimits(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetUsersWithLimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersWithLimits>>>
export type GetUsersWithLimitsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Query users between limits
 */
export const useGetUsersWithLimits = <TData = Awaited<ReturnType<typeof getUsersWithLimits>>, TError = AxiosError<HTTPValidationError>>(
 params?: GetUsersWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsersWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersWithLimitsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Creates a new user based on
 * @summary Create a new user
 */
export const createUser = (
    userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserResponse>> => {
    return axios.post(
      `/users`,
      userCreateRequest,options
    );
  }



export const getCreateUserMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: UserCreateRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: UserCreateRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUser>>, {data: UserCreateRequest}> = (props) => {
          const {data} = props ?? {};

          return  createUser(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
    export type CreateUserMutationBody = UserCreateRequest
    export type CreateUserMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create a new user
 */
export const useCreateUser = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: UserCreateRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all users
 */
export const getUsers = (
    params?: GetUsersParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserResponse[]>> => {
    return axios.get(
      `/users/infinite`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetUsersQueryKey = (params?: GetUsersParams,) => [`/users/infinite`, ...(params ? [params]: [])] as const;
  

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<HTTPValidationError>>(params?: GetUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get all users
 */
export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = AxiosError<HTTPValidationError>>(
 params?: GetUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Get a user by their id
 * @summary Get a particular user
 */
export const getUserById = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserDetailResponse>> => {
    return axios.get(
      `/users/${id}`,options
    );
  }


export const getGetUserByIdQueryKey = (id: string,) => [`/users/${id}`] as const;
  

    
export const getGetUserByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserById>>, TError = AxiosError<HTTPValidationError>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserByIdQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({ signal }) => getUserById(id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserById>>>
export type GetUserByIdQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get a particular user
 */
export const useGetUserById = <TData = Awaited<ReturnType<typeof getUserById>>, TError = AxiosError<HTTPValidationError>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Delete a user from the database

The endpoint will look to see if the user exists, and if so
will attempt to delete the user from the database and
return a 204 response. If the user does not exist, a 404
 * @summary Delete a particular user
 */
export const deleteUser = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/users/${id}`,options
    );
  }



export const getDeleteUserMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteUser(id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
    
    export type DeleteUserMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete a particular user
 */
export const useDeleteUser = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a particular user
 */
export const updateUser = (
    id: string,
    userRequest: UserRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/users/${id}`,
      userRequest,options
    );
  }



export const getUpdateUserMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{id: string;data: UserRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{id: string;data: UserRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, {id: string;data: UserRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  updateUser(id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
    export type UpdateUserMutationBody = UserRequest
    export type UpdateUserMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update a particular user
 */
export const useUpdateUser = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{id: string;data: UserRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    