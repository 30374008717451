function Videos() {
    return (
        <div className="flex flex-col items-center mx-4 md:mx-auto md:max-w-2xl">
            <div>
                <iframe
                    src="https://player.vimeo.com/video/362455948?h=a3e1260c77"
                    width="640"
                    height="427"
                    allow="autoplay; fullscreen; picture-in-picture">
                </iframe>
                <p className="py-2 text-center">
                    <a href="https://vimeo.com/362455948">
                        Uncle Stan Grant discusses his language journey and the meaning of Wagga Wagga</a>
                </p>
            </div>
            <div>
                <iframe
                    src="https://player.vimeo.com/video/362458543?h=362ba43cfa"
                    width="640"
                    height="427"
                    allow="autoplay; fullscreen; picture-in-picture">
                </iframe>
                <p className="py-2 text-center">
                    <a href="https://vimeo.com/362458543">
                        R U OK in Wiradjuriy</a>
                </p>
            </div>

        </div>
    )
}
export default Videos;