import React from 'react';

function Login() {
    return(
    <div className="flex flex-col justify-between">
        <img 
        className="mb-10 rounded-3xl"
        height={128}
        width={128}
        src={require("assets/icon-guyulgang.jpg")} 
        alt="Logo - guyulgang"/>
        <form className="flex flex-col bg-white shadow-2xl shadow-white rounded-3xl">
            <p>Hello</p>        
        </form>
    </div>);
}

export default Login;