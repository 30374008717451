import * as React from "react"
import { Link } from 'react-router-dom';

const AppSelector = () => { 
    return(
        <div className="text-white">
        <h2 className="mb-2 text-4xl font-semibold text-center">Our digital showcase</h2>
        <p className="mb-20 text-center text-gray-300 ">
            Click through an icon to explore our digital products.
        </p>
        <section className="grid grid-cols-1 gap-2 pb-16 mx-4 text-center lg:mx-auto lg:grid-cols-4 lg:max-w-screen-lg">
          <div className="flex flex-col items-center justify-center">
              <h3 className="mb-6 text-2xl font-semibold">Yalbilinya</h3>
              <Link className="shadow-2xl opacity-20 shadow-white rounded-3xl"
                to="">
                <img 
                className="rounded-3xl"
                height={128}
                width={128}
                src={require("assets/icon-yalbilinya.png")} 
                alt="Logo - Yalbilinya"/>
              </Link>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="mb-6 text-2xl font-semibold">Gurray</h3>
              <a className="shadow-2xl shadow-white rounded-3xl" 
                href="https://wcclp.com.au/gurray/">
                <img 
                className="rounded-3xl"
                height={128}
                width={128}
                src={require("assets/icon-gurray.png")}
                alt="WCC - Gurray"/>
              </a>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="mb-6 text-2xl font-semibold">Dictionaries</h3>
              <Link className="shadow-2xl shadow-white rounded-3xl" 
                to="/pages/dictionary">
                <img
                className="rounded-3xl"
                height={128}
                width={128}
                alt="Logo - WCC Dictionaries"
                src={require("assets/icon-wcc-dictionary.png")} />
              </Link>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="mb-6 text-2xl font-semibold">Dhaga</h3>
              <Link className="shadow-2xl opacity-20 shadow-white rounded-3xl" 
                to="">
                <img
                className="rounded-3xl"
                height={128}
                width={128}
                alt="Logo - Dhaga"
                src={require("assets/icon-dhaga.png")}/>
              </Link>
            </div>
        </section>
        </div>
    );
};

export default AppSelector;