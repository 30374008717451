import React from 'react';


function Footer() {
    return(
    <footer className="flex flex-col items-center mt-10 text-center">
        <img src={require("assets/logo-ila.png")}
            alt="Yorta Yorta"
            width={400}
        />
        <p>
            Supported through the Australian government's<br/>
            Indigenous Languages and Arts program.
        </p>
        <p className="mt-10 text-gray-400">
        &copy; Wiradjuri Condobolin Corporation Language Program.
        </p>
        <div className="flex flex-col items-start mt-8 lg:items-center lg:flex-row">
            <div className="flex flex-row items-center mb-4 lg:mb-0">
                <img 
                    src={require("assets/flag-australian-aboriginal.png")} 
                    width="44" 
                    height="27" 
                    className="mr-2" 
                    alt="Flag Australian Aboriginal"
                />
                <img src={require("assets/flag-torres-strait-islanders.png")} width="56" height="28" className="mr-4" alt="Flag Torres Strait Islanders"/>
            </div>
            <div className="flex flex-col items-start text-sm text-gray-500">
                <p>We would like to acknowledge the Traditional Owners of the land on which we meet, work and live.</p>
                <p>We recognise and respect the continuing connection to the land, waters and communities.</p>
                <p>We pay our respects to Elders past, present, and to all First Nations people.</p>
            </div>
        </div>

    </footer>
    );
}

export default Footer;