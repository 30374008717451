import {
    useParams,
    Link
} from 'react-router-dom';

import {
    useGetLanguageById,
} from 'api/language/language';

function LanguageOverview() {

    const { id }: any = useParams();

    const {
        data: language
    } = useGetLanguageById(id);



    return (
        <div className="flex flex-col flex-1 h-full text-black bg-white">
            <h1 className="py-4 mb-2 text-3xl font-bold text-center">{language?.data.name}</h1>
            <p className="my-10 text-2xl text-center">Please choose what you'd like to do?</p>
            <ul className="grid max-w-xl grid-cols-3 gap-6 mx-auto">
                <li className="">
                    <Link to="words" className="flex flex-col items-center p-10 text-white bg-black border-2 border-gray-200 shadow-lg rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                        </svg>
                        <span className="mt-2 text-xl">Words</span>
                    </Link>
                </li>
                <li className="">
                    <Link to="categories" className="flex flex-col items-center p-10 text-white bg-black border-2 border-gray-200 shadow-lg rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                        </svg>
                        <span className="mt-2 text-xl">Categories</span>
                    </Link>
                </li>
                <li className="">
                    <Link to="word-types" className="flex flex-col items-center p-10 text-white bg-black border-2 border-gray-200 shadow-lg rounded-3xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                        </svg>
                        <span className="mt-2 text-xl">Types</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default LanguageOverview;