import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AppSelector from "components/app-selector";
import Footer from "components/Footer";

function App() {
  return (
    <div className="flex flex-col justify-between w-screen h-screen p-8 text-center text-white app">
      <nav>
        <ul className="flex items-center justify-between">
          <li className="text-gray-300 underline">
            <RouterLink to="/auth/login">Request access</RouterLink>
          </li>
          <li>
            <RouterLink 
              className="px-6 py-4 font-semibold text-black bg-white rounded-full"
              to="/auth/login">
                Login to Guyulgang
            </RouterLink>
          </li>
        </ul>
      </nav>
      <header className="flex items-center justify-center w-full mb-20">
        <a href="https://wcclp.com.au">
          <img
            src={require("assets/logo-wcclp-inverted.png")}
            alt="Wiradjuri Condobolin Corporation Language Program Logo Mark"
            width={128}
            height={128}/>
        </a>
        <h1 className="sr-only">Wiradjuri Condobolin Corporation Language Program</h1>
      </header>
      <AppSelector/>
      <Footer/>
    </div>
  );
}

export default App;
