
import { useParams, Link } from 'react-router-dom';

import {
    useGetLanguageById,
    useGetWords
} from 'api/language/language';
  
import WordHistory from './history';
import { Helmet } from 'react-helmet';

function WordOverview() {

    const { id } :any = useParams();

    const { 
        data: words
    } = useGetWords(id);

    const {
        data: language
    } = useGetLanguageById(id);
    
    return(
        <>
        <Helmet>
          <title>Overview</title>
        </Helmet>
        <div className="p-10">
  
        <div className="flex items-center justify-between mb-10">
          <h1 className="mb-2 text-3xl font-bold">{language?.data.name}</h1>
          <article className="flex items-center">
            <h2 className="mr-2 text-xl font-semibold">{words?.data.length}</h2>
            <p className="">words</p>
          </article>

        </div>
        <p className="mb-10 text-center text-gray-700">
          Search for words on the left or click one to edit,<br/>
          or click the add button to add one
        </p>
        <Link 
          relative="path"
          to={`../words/new`}
          className="flex justify-center text-green-700"
          >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        </Link>
      </div>
      <WordHistory />
      </>
    );
}

export default WordOverview;