import { Link } from "react-router-dom";

function Navigation() {

    const links = [
        {
            title: "Letters",
            path: "letters",
        },
        {
            title: "Category",
            path: "categories",
        },
        {
            title: "Traditional",
            path: "traditional",
        },
        {
            title: "Favourites",
            path: "favourites",
        },
        {
            title: "Search",
            path: "search",
        },

    ]

    return (
        <nav className="grid grid-cols-1 gap-8 mx-8 md:mx-auto md:grid-cols-2 md:max-w-md">
            {links.map((link) => (
                <Link to={`../${link.path}`} key={link.path}
                    className="p-6 text-3xl text-center bg-black border-2 rounded-lg md:p-10 border-accent-barngarla text-accent-barngarla">
                    {link.title}
                </Link>
            ))}
        </nav>
    )
}

export default Navigation;