import {
    Outlet
} from "react-router-dom";

import Header from "components/app-header";
import Footer from "components/app-footer";

function UserAdminIndex() {
    return(
        <div className="flex flex-col w-screen h-screen overflow-hidden">
            <Header/>
            <main className="flex-1 text-black bg-white">
                <Outlet />
            </main>
            <Footer/>
        </div>
    );
}

export default UserAdminIndex;