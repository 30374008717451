import {
    redirect,
    Form,
    useParams
} from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';

import {
    updateWordType,
    getGetWordTypeWithLimitsQueryKey,
    useGetWordTypeById,
} from 'api/language/language';
import { WordTypeRequest } from 'api/models';
import { Helmet } from 'react-helmet';

export const loader =
    (queryClient: QueryClient) =>
        async ({ request, params }: any) => {
            const query = getGetWordTypeWithLimitsQueryKey(
                params.id,
                params.wordTypeId
            );
            return (queryClient.getQueriesData(query) ??
                (await queryClient.fetchQuery(query)));
        };

export const action = (queryClient: QueryClient) =>
    async ({ params, request }: any) => {
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await updateWordType(
            params.id,
            params.wordTypeId,
            updates as WordTypeRequest
        );
        return redirect('../');
    };

function WordTypeEditor() {

    const { id, wordTypeId }: any = useParams();

    // Made available by orval
    const {
        data: wordType
    } = useGetWordTypeById(id, wordTypeId);

    console.log(wordTypeId);

    return (
        <div>
            <Helmet>
                <title>Edit Word Type</title>
            </Helmet>
            <Form
                method="patch"
                id="update-word-type"
                className="flex flex-col max-w-md mx-auto"
            >
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="name"
                >
                    Word Type Name:
                    <input
                        autoFocus
                        placeholder="Word Type name"
                        aria-label="Name"
                        type="text"
                        name="name"
                        className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        defaultValue={wordType?.data.name}
                    />

                </label>
                <button
                    type="submit"
                    className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save
                </button>
            </Form>
        </div>
    );
}

export default WordTypeEditor;