import {
    useParams,
    Link,
    Form,
} from 'react-router-dom';

import {
    QueryClient,
} from 'react-query';

import {
    CategoryResponse,
} from 'api/models';

import {
    useGetCategoriesWithLimits,
    getGetCategoriesWithLimitsQueryKey,
} from 'api/language/language';

/**
 * Loader that the router uses to get content, this
 * returns an async function that it can use.
 * 
 * react-query takes the key as an array, which would
 * represent the collection as well as individual items.
 * 
 * getGetUsersWithLimitsQueryKey is generated by orval
 * which provides a construct similar to what's reference
 * in the documentation.
 * 
 * const contactListQuery = (q) => ({
*    queryKey: ["contacts", "list", q ?? "all"],
*    queryFn: () => getContacts(q),
*  })
 * 
 * 
 * @param queryClient 
 * @returns function that returns a promise
 */
export const loader =
    (queryClient: QueryClient) =>
        async (params: any) => {
            return (
                queryClient.getQueriesData(getGetCategoriesWithLimitsQueryKey(params.languageId)) ??
                (await queryClient.fetchQuery(getGetCategoriesWithLimitsQueryKey(params.languageId)))
            );
        }

function ListCategories() {

    const { id }: any = useParams();

    const {
        data: categories,
        refetch
    } = useGetCategoriesWithLimits(id, {
        offset: 0,
        limit: 100
    });

    return (
        <>
            <div className="flex items-center justify-between mb-4">
                <h1 className="mb-4 text-4xl font-bold">Categories</h1>
                <Link className="p-4 text-white bg-green-700 rounded-sm" to="new">New Category</Link>
            </div>
            {categories?.data.length === 0 && (
                <div className="py-10 text-center">
                    <p className="text-2xl text-gray-500">No categories found. Please add one</p>
                </div>)}

            <ul className="max-w-2xl mx-auto overflow-y-auto text-gray-700">
                {categories?.data.map((category: CategoryResponse) => (
                    <li key={category.id} className="flex items-center justify-between mb-4">
                        <p className="font-semibold">
                            {category.name}
                        </p>
                        <nav className="grid grid-cols-2 text-center">
                            <Form
                                method="post"
                                action={`${category.id}/destroy`}
                                className=""
                                onSubmit={(event) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (!confirm("Please confirm you want to delete this record.")) {
                                        event.preventDefault();
                                    }
                                }}>
                                <button
                                    className="text-red-400 hover:underline hover:text-red-600"
                                    type="submit">
                                    Delete
                                </button>
                            </Form>
                            <Link
                                className="rounded-md ml-8 bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                to={`${category.id}/edit`}>
                                Edit
                            </Link>

                        </nav>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default ListCategories;