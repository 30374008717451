import { useState, Fragment } from 'react'
import { Tab } from '@headlessui/react'

import {
    useParams,
} from 'react-router-dom';

import {
    format,
    parseISO,
} from 'date-fns';

import {
    CategoryResponse,
    WordTypeResponse,
    WordResponse,
    WordChangeLogResponse
} from 'api/models';

import {
    useGetCategoriesWithLimits,
    useGetWordById,
    assignWordToCategory,
    removeWordFromCategory,
    assignWordToType,
    useGetWordTypeWithLimits,
    useGetWordChangeLogById,
} from 'api/language/language';

export default function WordMetadata() {

    let [tabs] = useState(["Categories", "Word Type", "History"]);

    function classNames(...classes:any) {
        return classes.filter(Boolean).join(' ')
      }
      
      
    const {
        id,
        wordId
    }: any = useParams();

    const {
        data: wordChangeLog
    } = useGetWordChangeLogById(id, wordId);

    const { data: word } = useGetWordById(id, wordId);

    const {
        data: categories,
    } = useGetCategoriesWithLimits(id, {
        offset: 0,
        limit: 100
    });

    const {
        data: wordTypes
    } = useGetWordTypeWithLimits(id, {
        offset: 0,
        limit: 100
    });

    // Check to see if the word is in the category
    const wordHasCategory = (category: CategoryResponse) => {
        return word?.data.categories?.some((wordCategory: CategoryResponse) => {
            return wordCategory.id === category.id;
        });
    }

    const handleOnChange = async (event: any) => {
        const target = event.target;
        if (target.checked) {
            await assignWordToCategory(id, wordId, target.value);
        }
        else {
            await removeWordFromCategory(id, wordId, target.value);
        }
    }

    const handleWordTypeSelection = async (event: any) => {
        const target = event.target;
        await assignWordToType(id, wordId, target.value);
    }

    const CategorySelector = () => {
        return(
        <div className="flow-root p-4">
            <h2 className="mb-2 text-xl font-bold">Categories</h2>
            <ul className="">
                {categories?.data.map((category: CategoryResponse) => (
                    <li key={category.id} className="flex items-center my-1">
                        <label htmlFor={category.id} className="">
                            <input
                                id={category.id}
                                aria-describedby="wordOfTheDay-description"
                                name={category.id}
                                type="checkbox"
                                value={category.id}
                                defaultChecked={wordHasCategory(category)}
                                onChange={handleOnChange}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                            />
                            <span className="ml-2">
                                {category.name}
                            </span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>);
    };

    const WordTypeSelector = () => {
        return(
            <div>
            <h2 className="mt-4 mb-2 text-xl font-bold">Word Type</h2>
            <select className="p-2 border border-gray-300 rounded-sm" onChange={handleWordTypeSelection}>
                <option disabled selected value=""> -- select an option -- </option>
                {wordTypes?.data.map((wordType: WordTypeResponse) => (
                    <option
                        selected={word?.data.wordTypeId === wordType.id}
                        className="flex items-center my-1"
                        key={wordType.id}
                        value={wordType.id}>{wordType.name}</option>
                ))}
            </select>
            </div>
        );
    }

    const WordChangeLog = () => {
        return(
            <ul className="mt-2">
                {wordChangeLog?.data.map((wordChange: WordChangeLogResponse) => (
                    <li key={wordChange.id} className="flex flex-col my-1 mb-2">
                        <span className="block font-bold">
                            {wordChange.status}
                        </span>
                        <span>
                            on {format(parseISO(wordChange.createdAt), "dd MMM yyyy")}
                        </span>
                    </li>
                ))}
            </ul>
        )};

    return(
        <div className="px-4 py-2 sm:px-0">
        <Tab.Group>
        <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20">
          {tabs.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
            <Tab.Panel>
                <CategorySelector />
            </Tab.Panel>
            <Tab.Panel>
                <WordTypeSelector />
            </Tab.Panel>
            <Tab.Panel>
                <WordChangeLog/>
            </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      </div>
    );
}
