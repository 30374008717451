import {
    Outlet
} from "react-router-dom";

import Header from "./header";
import Footer from "./footer";

function LanguageBrowser() {

    return (
        <div className="flex flex-col min-h-screen text-white bg-cover bg-jawoyn">
            <Header />
            <main className="flex-1">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}

export default LanguageBrowser;