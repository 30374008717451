import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import Axios from 'axios';
import axios from 'axios';

import { ReactQueryDevtools } from 'react-query/devtools'

import './index.css';
import './i18n';

import reportWebVitals from './reportWebVitals';

import App from './App';
import Authentication from './routers/auth';
import ErrorPage from './error-page';
import ForgotPassword from './routers/auth/forgot-password';
import Menu, {
  loader as menuLoader
} from './routers/auth/menu';

import Guyulgang from './routers/guyulgang';
import WordIndex from './routers/guyulgang/words';
import WordOverview from './routers/guyulgang/words/overview';
import WordEditor, {
  loader as wordLoader,
  action as wordUpdateAction
} from './routers/guyulgang/words/edit';

import WordCreator, {
  action as wordCreateAction
} from './routers/guyulgang/words/new';

import Logout, {
  loader as logoutLoader
} from 'routers/auth/logout';

import Login, {
  action as loginAction
} from 'routers/auth/login';
import LanguageChooser, {
  loader as languageChooserLoader
} from 'routers/guyulgang/language-chooser';

import AdminContainer from 'routers/admin';
import UserAdminIndex, {
  loader as userLoader
} from 'routers/admin/users/index';

import UserEditor, {
  loader as userEditorLoader,
  action as userUpdateAction
} from 'routers/admin/users/edit';

import UserCreator, {
  action as userCreateAction
} from 'routers/admin/users/new';

import {
  action as destroyUserAction
} from 'routers/admin/users/destroy';

/**
 * @group Languages
 */

import LanguageAdminIndex from 'routers/admin/languages';
import LanguageAdminAdd from 'routers/admin/languages/new';
import LanguageAdminEdit from 'routers/admin/languages/edit';
import {
  loader as languageLoader
} from 'routers/admin/languages';
import {
  action as destroyLanguageAction
} from 'routers/admin/languages/destroy';
import {
  action as createLanguageAction
} from 'routers/admin/languages/new';
import {
  action as updateLanguageAction
} from 'routers/admin/languages/edit';

import UserProfile from 'routers/guyulgang/user-profile';
import LanguageOverview from 'routers/guyulgang/language-overview';

/***
 * @group Categories
 */
import LanguageCategory from 'routers/guyulgang/categories';
import LanguageCategoryIndex, {
  loader as categoryLoader
} from 'routers/guyulgang/categories/list';
import LanguageCategoryNew, {
  action as createCategoryAction
} from 'routers/guyulgang/categories/new';
import LanguageCategoryEdit, {
  action as updateCategoryAction
} from 'routers/guyulgang/categories/edit';
import {
  action as destroyCategoryAction
} from 'routers/guyulgang/categories/destroy';

/** 
 * Word Types
 */
import WordType from 'routers/guyulgang/word-types';
import WordTypeIndex, {
  loader as wordTypeLoader
} from 'routers/guyulgang/word-types/list';
import WordTypeNew, {
  action as createWordTypeAction
} from 'routers/guyulgang/word-types/new';
import WordTypeEdit, {
  action as updateWordTypeAction
} from 'routers/guyulgang/word-types/edit';
import {
  action as destroyWordTypeAction
} from 'routers/guyulgang/word-types/destroy';

import LanguageBrowser from 'routers/browser';
import LanguageBrowserNavigation from 'routers/browser/navigation';
import LanguageCategories from 'routers/browser/category';
import LanguageLetterView from 'routers/browser/letters';
import LanguageTraditionView from 'routers/browser/traditional';
import LanguageVideosView from 'routers/browser/videos';
import LanguageWordDetailView from 'routers/browser/word-detail';
import LanguageFavouritesView from 'routers/browser/favourites';
import LanguageWordsByLetter from 'routers/browser/letter-words';
import LanguageWordsByCategory from 'routers/browser/category-words';
import LanguageSearch from "routers/browser/search";
import LanguageAcknowledgement from "routers/browser/acknowledgement";
import LanguageAllWords from "routers/browser/all-words";

// Jawoyn
import JawoynLanguageBrowser from 'routers/jawoyn';
import JawoynLanguageBrowserNavigation from 'routers/jawoyn/navigation';
import JawoynLanguageCategories from 'routers/jawoyn/category';
import JawoynLanguageLetterView from 'routers/jawoyn/letters';
import JawoynLanguageTraditionView from 'routers/jawoyn/traditional';
import JawoynLanguageVideosView from 'routers/jawoyn/videos';
import JawoynLanguageWordDetailView from 'routers/jawoyn/word-detail';
import JawoynLanguageFavouritesView from 'routers/jawoyn/favourites';
import JawoynLanguageWordsByLetter from 'routers/jawoyn/letter-words';
import JawoynLanguageWordsByCategory from 'routers/jawoyn/category-words';
import JawoynLanguageSearch from "routers/jawoyn/search";
import JawoynLanguageAcknowledgement from "routers/jawoyn/acknowledgement";
import JawoynLanguageAllWords from "routers/jawoyn/all-words";

// Barngarla
import BarngarlaLanguageBrowser from 'routers/barngarla';
import BarngarlaLanguageBrowserNavigation from 'routers/barngarla/navigation';
import BarngarlaLanguageCategories from 'routers/barngarla/category';
import BarngarlaLanguageLetterView from 'routers/barngarla/letters';
import BarngarlaLanguageTraditionView from 'routers/barngarla/traditional';
import BarngarlaLanguageVideosView from 'routers/barngarla/videos';
import BarngarlaLanguageWordDetailView from 'routers/barngarla/word-detail';
import BarngarlaLanguageFavouritesView from 'routers/barngarla/favourites';
import BarngarlaLanguageWordsByLetter from 'routers/barngarla/letter-words';
import BarngarlaLanguageWordsByCategory from 'routers/barngarla/category-words';
import BarngarlaLanguageSearch from "routers/barngarla/search";
import BarngarlaLanguageAcknowledgement from "routers/barngarla/acknowledgement";
import BarngarlaLanguageAllWords from "routers/barngarla/all-words";


// Wiradjuri
import WiradjuriLanguageBrowser from 'routers/wiradjuri';
import WiradjuriLanguageBrowserNavigation from 'routers/wiradjuri/navigation';
import WiradjuriLanguageCategories from 'routers/wiradjuri/category';
import WiradjuriLanguageLetterView from 'routers/wiradjuri/letters';
import WiradjuriLanguageTraditionView from 'routers/wiradjuri/traditional';
import WiradjuriLanguageVideosView from 'routers/wiradjuri/videos';
import WiradjuriLanguageWordDetailView from 'routers/wiradjuri/word-detail';
import WiradjuriLanguageFavouritesView from 'routers/wiradjuri/favourites';
import WiradjuriLanguageWordsByLetter from 'routers/wiradjuri/letter-words';
import WiradjuriLanguageWordsByCategory from 'routers/wiradjuri/category-words';
import WiradjuriLanguageSearch from "routers/wiradjuri/search";
import WiradjuriLanguageAcknowledgement from "routers/wiradjuri/acknowledgement";
import WiradjuriLanguageAllWords from "routers/wiradjuri/all-words";


import PageIndex from 'routers/pages';
import PageDictionary from 'routers/pages/dictionary';

/**
 * QueryClient is what react-query uses to manage the cache
 * and the state of the queries.
 * 
 * This requires to be passed to react-router loaders and
 * actions for them to work with.
 */
const queryClient = new QueryClient();

// Set a base URL
// this is to work around deploying on cloudflare pages
Axios.defaults.baseURL = process.env.NODE_ENV === "development" ? '/api' : 'https://api.guyulgang.com/';

/**
 * Add the token before each request
 */
axios.interceptors.request.use(config => {
  if (config.headers) {
    const accessToken = window.localStorage.getItem('access_token');
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
}, error => {

});

/**
 * If the response is unautorised then goto the
 * login page
 */
axios.interceptors.response.use((config) => {
  return config;
}, (error) => {
  if (error.response.status === 401) {
    window.location.href = '/auth/login';
  }
  return Promise.reject(error);
});

/**
 * Router
 */
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "pages",
    element: <PageIndex />,
    children: [
      {
        path: "dictionary",
        element: <PageDictionary />,
      }
    ]
  },
  {
    path: "auth",
    element: <Authentication />,
    children: [
      {
        path: "login",
        action: loginAction(queryClient),
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "logout",
        loader: logoutLoader(queryClient),
        element: <Logout />,
      }
    ]
  },
  {
    path: "menu",
    element: <Menu />,
  },
  {
    path: "language/yorta-yorta",
    element: <LanguageBrowser />,
    children: [
      {
        path: "",
        element: <LanguageAcknowledgement />,
      },
      {
        path: "menu",
        element: <LanguageBrowserNavigation />,
      },
      {
        path: "categories",
        element: <LanguageCategories />,
      },
      {
        path: "all",
        element: <LanguageAllWords />,
      },
      {
        path: "letters",
        element: <LanguageLetterView />,
      },
      {
        path: "traditional",
        element: <LanguageTraditionView />,
      },
      {
        path: "videos",
        element: <LanguageVideosView />,
      },
      {
        path: "favourites",
        element: <LanguageFavouritesView />,
      },
      {
        path: "search",
        element: <LanguageSearch />,
      },
      {
        path: "category/:categoryId/words",
        element: <LanguageWordsByCategory />,
      },
      {
        path: "letter/:letter/words",
        element: <LanguageWordsByLetter />,
      },
      {
        path: "word/:wordId",
        element: <LanguageWordDetailView />,
      }
    ]
  },
  {
    path: "language/jawoyn",
    element: <JawoynLanguageBrowser />,
    children: [
      {
        path: "",
        element: <JawoynLanguageAcknowledgement />,
      },
      {
        path: "menu",
        element: <JawoynLanguageBrowserNavigation />,
      },
      {
        path: "categories",
        element: <JawoynLanguageCategories />,
      },
      {
        path: "all",
        element: <JawoynLanguageAllWords />,
      },
      {
        path: "letters",
        element: <JawoynLanguageLetterView />,
      },
      {
        path: "traditional",
        element: <JawoynLanguageTraditionView />,
      },
      {
        path: "videos",
        element: <LanguageVideosView />,
      },
      {
        path: "favourites",
        element: <JawoynLanguageFavouritesView />,
      },
      {
        path: "search",
        element: <JawoynLanguageSearch />,
      },
      {
        path: "category/:categoryId/words",
        element: <JawoynLanguageWordsByCategory />,
      },
      {
        path: "letter/:letter/words",
        element: <JawoynLanguageWordsByLetter />,
      },
      {
        path: "word/:wordId",
        element: <JawoynLanguageWordDetailView />,
      }
    ]
  },
  {
    path: "language/barngarla",
    element: <BarngarlaLanguageBrowser />,
    children: [
      {
        path: "",
        element: <BarngarlaLanguageAcknowledgement />,
      },
      {
        path: "menu",
        element: <BarngarlaLanguageBrowserNavigation />,
      },
      {
        path: "categories",
        element: <BarngarlaLanguageCategories />,
      },
      {
        path: "all",
        element: <BarngarlaLanguageAllWords />,
      },
      {
        path: "letters",
        element: <BarngarlaLanguageLetterView />,
      },
      {
        path: "traditional",
        element: <BarngarlaLanguageTraditionView />,
      },
      {
        path: "videos",
        element: <LanguageVideosView />,
      },
      {
        path: "favourites",
        element: <BarngarlaLanguageFavouritesView />,
      },
      {
        path: "search",
        element: <BarngarlaLanguageSearch />,
      },
      {
        path: "category/:categoryId/words",
        element: <BarngarlaLanguageWordsByCategory />,
      },
      {
        path: "letter/:letter/words",
        element: <BarngarlaLanguageWordsByLetter />,
      },
      {
        path: "word/:wordId",
        element: <BarngarlaLanguageWordDetailView />,
      }
    ]
  },
  {
    path: "language/wiradjuri",
    element: <WiradjuriLanguageBrowser />,
    children: [
      {
        path: "",
        element: <WiradjuriLanguageAcknowledgement />,
      },
      {
        path: "menu",
        element: <WiradjuriLanguageBrowserNavigation />,
      },
      {
        path: "categories",
        element: <WiradjuriLanguageCategories />,
      },
      {
        path: "videos",
        element: <WiradjuriLanguageVideosView />,
      },
      {
        path: "all",
        element: <WiradjuriLanguageAllWords />,
      },
      {
        path: "letters",
        element: <WiradjuriLanguageLetterView />,
      },
      {
        path: "traditional",
        element: <WiradjuriLanguageTraditionView />,
      },
      {
        path: "videos",
        element: <LanguageVideosView />,
      },
      {
        path: "favourites",
        element: <WiradjuriLanguageFavouritesView />,
      },
      {
        path: "search",
        element: <WiradjuriLanguageSearch />,
      },
      {
        path: "category/:categoryId/words",
        element: <WiradjuriLanguageWordsByCategory />,
      },
      {
        path: "letter/:letter/words",
        element: <WiradjuriLanguageWordsByLetter />,
      },
      {
        path: "word/:wordId",
        element: <WiradjuriLanguageWordDetailView />,
      }
    ]
  },
  {
    path: "guyulgang",
    element: <Guyulgang />,
    children: [
      {
        loader: languageChooserLoader(queryClient),
        path: "user-profile",
        element: <UserProfile />,

      },
      {
        loader: languageChooserLoader(queryClient),
        path: "languages",
        element: <LanguageChooser />,
      },
      {
        path: ":id",
        element: <LanguageOverview />,
      },
      {
        path: ":id/words",
        element: <WordIndex />,
        children: [
          {
            path: "",
            element: <WordOverview />,
          },
          {
            path: "new",
            element: <WordCreator />,
            action: wordCreateAction(queryClient),
          },
          {
            path: ":wordId/edit",
            action: wordUpdateAction(queryClient),
            element: <WordEditor />,
          },

        ]
      },
      {
        path: ":id/categories",
        loader: categoryLoader(queryClient),
        element: <LanguageCategory />,
        children: [
          {
            path: "",
            element: <LanguageCategoryIndex />,
          },
          {
            path: ":categoryId/edit",
            element: <LanguageCategoryEdit />,
            action: updateCategoryAction(queryClient)
          },
          {
            path: ":categoryId/destroy",
            element: <LanguageCategory />,
            action: destroyCategoryAction(queryClient)
          },
          {
            path: "new",
            element: <LanguageCategoryNew />,
            action: createCategoryAction(queryClient)
          }
        ]
      },
      {
        path: ":id/word-types",
        loader: wordTypeLoader(queryClient),
        element: <WordType />,
        children: [
          {
            path: "",
            element: <WordTypeIndex />,
          },
          {
            path: ":wordTypeId/edit",
            element: <WordTypeEdit />,
            action: updateWordTypeAction(queryClient)
          },
          {
            path: ":wordTypeId/destroy",
            element: <WordType />,
            action: destroyWordTypeAction(queryClient)
          },
          {
            path: "new",
            element: <WordTypeNew />,
            action: createWordTypeAction(queryClient)
          }
        ]
      },
    ]
  },
  {
    path: "admin",
    element: <AdminContainer />,
    children: [
      {
        path: "users",
        loader: userLoader(queryClient),
        children: [
          {
            path: "",
            element: <UserAdminIndex />,
            index: true
          },
          {
            path: "new",
            element: <UserCreator />,
            action: userCreateAction(queryClient)
          },
          {
            path: ":id/edit",
            element: <UserEditor />,
            loader: userEditorLoader(queryClient),
            action: userUpdateAction(queryClient)
          },
          {
            path: ":id/destroy",
            element: <UserEditor />,
            action: destroyUserAction(queryClient)
          }]
      },
      {
        path: "languages",
        loader: languageLoader(queryClient),
        children: [
          {
            path: "",
            element: <LanguageAdminIndex />,
            index: true
          },
          {
            path: "new",
            element: <LanguageAdminAdd />,
            action: createLanguageAction(queryClient)
          },
          {
            path: ":id/edit",
            element: <LanguageAdminEdit />,
            loader: userEditorLoader(queryClient),
            action: updateLanguageAction(queryClient)
          },
          {
            path: ":id/destroy",
            element: <LanguageAdminIndex />,
            action: destroyLanguageAction(queryClient)
          }
        ]
      }
    ]
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
