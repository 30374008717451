import {
    Link,
} from "react-router-dom";

import LogoWCC from "assets/logo-jawoyn.png";
import LogoILA from "assets/logo-ila.png";

function Acknowledgement() {
    return (
        <div className="mx-4 md:mx-auto md:max-w-2xl">

            <img src={LogoWCC} height={80} width={272} alt="WCC Logo mark" />
            <p className="my-10 font-bold text-gray-400">Empowering our language, our way!<br />
                Lisa Mumbin<br />Chair, Jawoyn Association Aboriginal Corporation</p>

            <p className="text-center text-gray-400">Spoken by</p>
            <p className="mb-10 text-center">Betty Berry<br />Chiyo Andrews<br />Esther Bulumbara<br />Nell Brown</p>

            <p className="mb-4 text-gray-400">
                "Jawoyn-English Dictionary and English Finder-List" (2022) by Francesca Merlan, Pascale Jacq, Phyllis Wiynjorroj, Peter Jatbula, Nipper Daybilama Brown, Sandy Barraway, Sarah Flora, and others.
            </p>
            <p className="mb-4 text-gray-400">
                We thank our elders (both past and present) for their support and guidance in maintaining our Language and Culture.
            </p>

            <p className="mb-4 text-gray-400">
                Resource created by WCC Language Program and Calytrix Communication with support from the Australian Government’s Indigenous Languages and Arts program.
            </p>
            <img src={LogoILA} width={350} height={92} className="mx-auto" />
            <p className="mb-4 text-gray-400">Supported through the Australia Government's Indigenous Language and Arts program.</p>



            <Link to="menu" className="block w-full py-4 mt-10 font-bold text-center rounded-lg bg-accent-jawoyn">
                Continue
            </Link>
        </div>
    )
}

export default Acknowledgement;