import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";

function Header() {

    const navigate = useNavigate();
    const { name } = useParams();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="w-full">
            <header className="flex items-center justify-between mx-4 my-6 md:my-10 md:mx-auto md:max-w-2xl">
                <button onClick={() => { goBack(); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z" clipRule="evenodd" />
                    </svg>
                </button>
                <h1 className="text-4xl font-bold">Yorta Yorta</h1>
                <Link to="/language/yorta-yorta/menu">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                    </svg>
                </Link>
            </header>
        </div>
    );
}

export default Header;