import { useEffect, useRef, useState } from 'react';

import {
    useParams,
    redirect,
    Form,
    Link,
} from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';
import { parseISO, formatRelative, set } from 'date-fns'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import {
    useGetWordById,
    getGetWordByIdQueryKey,
    updateWord,
    createWordAudioFile,
    createWordImageFile,
    useGetLanguageById,
    getGetWordsWithLimitsQueryKey,
    deleteWordImage,
    deleteWordAudio
} from 'api/language/language';

import { WordRequest } from 'api/models';

import WordMetadata from './metadata';
import { Helmet } from 'react-helmet';

export const loader =
    (queryClient: QueryClient) =>
        async ({ request, params }: any) => {
            const query = getGetWordByIdQueryKey(params.id, params.wordId);
            return (queryClient.getQueriesData(query) ??
                (await queryClient.fetchQuery(query)));
        };

export const action = (queryClient: QueryClient) =>
    async ({ params, request }: any) => {
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await updateWord(params.id, params.wordId, updates as WordRequest);
        // Invalidate to get a copy
        queryClient.invalidateQueries(getGetWordsWithLimitsQueryKey(params.id));
        queryClient.invalidateQueries(getGetWordByIdQueryKey(params.id, params.wordId));
        redirect(".");
        return null;
    };

function WordEditor() {

    const formRef = useRef<HTMLFormElement>(null);

    const [ imageUploading, setImageUploading ]  = useState(false);
    const [ imageRemoving, setImageRemoving ] = useState(false);
    const [ audioUploading, setAudioUploading ] = useState(false);
    const [ audioRemoving, setAudioRemoving ] = useState(false);
    
    const {
        id,
        wordId
    }: any = useParams();

    const { data: language } = useGetLanguageById(id);

    const {
        data: word,
        isFetching
    } = useGetWordById(id, wordId, {});

    useEffect(() => {
        formRef.current?.reset();
    }, [word]);

    const handleAudioFileUpload = (e: any) => {
        e.preventDefault();
        const file = e.nativeEvent.target[0].files[0];

        setAudioUploading(true);
        createWordAudioFile(id, wordId, {
            fileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
        }).then((res: any) => {
            const requestOptions: any = {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                body: file,
            };
            fetch(res.data.presignedUploadUrl, requestOptions).then(
                (res) => {
                    setAudioUploading(false);
                    window.location.reload();
                }
            )
        }).catch((err) => {
            console.log(err);
            setAudioUploading(false);
        });
    }

    const handleImageFileUpload = (e: any) => {
        e.preventDefault();
        const file = e.nativeEvent.target[0].files[0];

        setImageUploading(true);
        createWordImageFile(id, wordId, {
            fileName: file.name,
            mimeType: file.type,
            fileSize: file.size,
        }).then((res: any) => {
            const requestOptions: any = {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                body: file,
            };
            fetch(res.data.presignedUploadUrl, requestOptions).then(
                (res) => {
                    setImageUploading(false);
                    window.location.reload();
                }
            )
        }).catch((err) => {
            console.log(err);
            setImageUploading(false);
        });
    }

    const handleImageRemove = (e: any) => {
        e.preventDefault();
        setImageRemoving(true);
        deleteWordImage(id, wordId).then((res: any) => {
            setImageRemoving(false);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            setImageRemoving(false);
        });
    }

    const handleAudioRemove = (e: any) => {
        e.preventDefault();
        setAudioRemoving(true);
        deleteWordAudio(id, wordId).then((res: any) => {
            setAudioRemoving(false);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            setAudioRemoving(false);
        });
    }

    const TitleBar = () => {
        return (
            <aside className="flex items-center justify-between p-4 text-black">
                <Link to="../"
                    type="button"
                    className="inline-flex items-center px-6 py-3 text-base font-medium text-gray-600 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Close
                </Link>
                <p>Last updated {word?.data.updatedAt && formatRelative(parseISO(word?.data.updatedAt), new Date())}</p>
                <button
                    type="submit"
                    className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-orange-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save
                </button>
            </aside>
        );
    };


    return (
        <>
            <Helmet>
                <title>{word?.data.english}</title>
            </Helmet>
            <div className="overflow-y-auto pb-32">
                <Form className="flex flex-col justify-between" method="put" id="update-word" ref={formRef}>
                    <TitleBar />
                    <div className="flex-1 p-10">
                        <label htmlFor="english" className="mt-8 text-gray-500">
                            English:
                            <input
                                autoFocus
                                name="english"
                                type="text"
                                className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                                placeholder="e.g abandon"
                                defaultValue={word?.data.english} />
                        </label>
                        <label className="mt-8 text-gray-500">
                            {language?.data.name}:
                            <input
                                name="indigenous"
                                type="text"
                                className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                                placeholder="e.g wamjamdogolinya"
                                defaultValue={word?.data.indigenous} />
                        </label>
                        {/* <label className="mt-8 text-gray-500">
                            Pronunciation:
                            <input
                                name="pronunciation"
                                type="text"
                                className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                                placeholder="How do you say this word"
                                defaultValue={word?.data.pronunciation} />
                        </label> */}

                        <label className="mt-8 text-gray-500">
                            Description:
                            <input
                                name="description"
                                type="text"
                                className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                                placeholder="Describe this word further"
                                defaultValue={word?.data.description} />
                        </label>
                        <div className="relative flex items-start mt-2">
                            <div className="flex items-center h-5">
                                <input
                                    id="wordOfTheDay"
                                    aria-describedby="wordOfTheDay-description"
                                    name="wordOfTheDay"
                                    type="checkbox"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                    defaultChecked={word?.data.wordOfTheDay ? true : false}
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="wordOfTheDay" className="font-medium text-gray-700">
                                    Word of the day
                                </label>
                                <span id="wordOfTheDay-description" className="text-gray-500">
                                    <span className="sr-only">Word of the day </span>, item will be shown in contents of the word of the day app widget.
                                </span>
                            </div>
                        </div>
                    </div>
                </Form>
                <h2 className="px-4 text-2xl font-medium text-gray-800">Audio</h2>
                {!word?.data.audioFile && (
                    <form className="flex flex-col p-4 m-6 border border-orange-100 rounded-md bg-orange-50" method="post" onSubmit={handleAudioFileUpload}>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Audio file
                            <input
                                required
                                accept="audio/mp3"
                                className="block w-full p-2 mt-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                aria-describedby="file_input_help"
                                type="file"
                            />
                        </label>
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">Please upload MP3 files only.</p>
                        <input disabled={audioUploading} type="submit" className="px-4 py-2 mt-4 text-white bg-orange-400 rounded-md" value={audioUploading ? "Uploading ..." : "Upload"} />
                    </form>
                )}
                {word?.data.audioFile && (
                    <div className="flex flex-col justify-center p-4 mb-4">
                        <div className="flex items-center">
                            <a 
                                href={word?.data.audioFile.presignedDownloadUrl} 
                                target="_blank" rel="noreferrer" 
                                className="block px-4 py-2 mx-auto mb-2 text-center text-white bg-blue-500 rounded-full w-36">
                                Download
                            </a>
                            <button disabled={audioRemoving} className="px-4 py-2 mx-auto mb-2 text-white bg-red-500 rounded-full w-36" onClick={handleAudioRemove}>
                                {audioRemoving ? 'Removing...' : 'Remove'}
                            </button>
                        </div>
                        <AudioPlayer
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            src={word?.data.audioFile.presignedDownloadUrl}
                        />
                    </div>
                )}
                <h2 className="px-4 text-2xl font-medium text-gray-800">Image</h2>
                {!word?.data.imageFile && (
                    <form className="flex flex-col p-4 m-6 border rounded-md border-rose-100 bg-rose-50" method="post" onSubmit={handleImageFileUpload}>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Image
                            <input
                                required
                                accept="image/png, image/jpeg"
                                className="block w-full p-2 mt-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                aria-describedby="file_input_help"
                                type="file"
                            />
                        </label>
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">Please upload JPG or PNG files.</p>
                        <input disabled={imageUploading} type="submit" className="px-4 py-2 mt-4 text-white rounded-md bg-rose-400" value={imageUploading ? 'Uploading ...':'Upload'} />
                    </form>
                )}
                {word?.data.imageFile && (
                    <div className="flex flex-col justify-center p-4 mb-4">
                        <div className="flex items-center">
                            <a 
                                href={word?.data.imageFile.presignedDownloadUrl} 
                                target="_blank" rel="noreferrer" 
                                className="block px-4 py-2 mx-auto mb-2 text-center text-white bg-blue-500 rounded-full w-36">
                                Download
                            </a>
                            <button disabled={imageRemoving} className="px-4 py-2 mx-auto mb-2 text-white bg-red-500 rounded-full w-36" onClick={handleImageRemove}>
                                {imageRemoving ? "Removing ..." : "Remove"}
                            </button>
                        </div>
                        <img src={word?.data.imageFile.presignedDownloadUrl} alt={word?.data.english} />
                    </div>
                )}
            </div>
            <WordMetadata />
        </>
    );
};

export default WordEditor;