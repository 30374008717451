
import {
    redirect,
    Link,
    Form,
    useParams,
} from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';

import {
    createWord,
    useGetLanguageById,
} from 'api/language/language';

import { WordRequest } from 'api/models';

import WordMetadata from './metadata';
import { Helmet } from 'react-helmet';

export const action = (queryClient: QueryClient) =>
    async ({ params, request }: any) => {
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        const payload = updates as WordRequest;

        payload.wordOfTheDay = updates.wordOfTheDay === 'on' ? true : false;

        const { data: word } = await createWord(params.id, payload);

        // If this don't work then go back
        return redirect(`../${word.id}/edit`);
    };

function WordCreator() {

    const {
        id,
    }: any = useParams();

    const { data: language } = useGetLanguageById(id);

    const TitleBar = () => {
        return (
            <aside className="flex items-center justify-between p-4 text-black">
                <Link
                    to="../"
                    type="button"
                    className="inline-flex items-center px-6 py-3 text-base font-medium text-red-600 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Cancel
                </Link>
                <button
                    type="submit"
                    className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save
                </button>
            </aside>
        );
    };

    return (
        <>
            <Helmet>
                <title>New Word</title>
            </Helmet>
            <Form className="flex flex-col justify-between" method="put" id="update-word">
                <TitleBar />
                <div className="flex-1 p-10">
                    <label htmlFor="english" className="mt-8 text-gray-500">
                        English:
                        <input
                            autoFocus
                            name="english"
                            type="text"
                            className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                            placeholder="e.g abandon"
                        />
                    </label>
                    <label className="mt-8 text-gray-500">
                        {language?.data.name}:
                        <input
                            name="indigenous"
                            type="text"
                            className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                            placeholder="e.g wamjamdogolinya"
                        />
                    </label>
                    <label className="mt-8 text-gray-500">
                        Pronunciation:
                        <input
                            name="pronunciation"
                            type="text"
                            className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                            placeholder=""
                        />
                    </label>
                    <label className="mt-8 text-gray-500">
                        Description:
                        <input
                            name="description"
                            type="text"
                            className="w-full px-4 py-2 mt-2 mb-4 text-lg font-semibold border-2 border-gray-400 rounded-md"
                            placeholder=""
                        />
                    </label>
                    <div className="relative flex items-start mt-2">
                        <div className="flex items-center h-5">
                            <input
                                id="wordOfTheDay"
                                name="wordOfTheDay"
                                aria-describedby="wordOfTheDay-description"
                                type="checkbox"
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor="wordOfTheDay" className="font-medium text-gray-700">
                                Word of the day
                            </label>
                            <span id="wordOfTheDay-description" className="text-gray-500">
                                <span className="sr-only">Word of the day </span>, item will be shown in contents of the word of the day app widget.
                            </span>
                        </div>
                    </div>

                </div>
            </Form>
            <WordMetadata />
        </>
    );
};

export default WordCreator;