import { 
    Link,
    useParams
} from "react-router-dom";

import YortaYorta from "data/YortaYorta.json";

function AllWords() {

    const { categoryId } = useParams();

    const words = YortaYorta.categories
        .map(category => category.entries)
        .flat(1)

    return (
        <>
        <h1 className="max-w-sm p-4 mx-auto mb-6 text-2xl font-bold md:max-w-2xl md:p-0">
            All Words
        </h1>
        <ul className="mx-auto divide-y-2 divide-gray-700 md:max-w-2xl">
            {words.map((word:any) => (
            <li key={word.id} className="px-2 px-4 py-4">
                <Link to={`../word/${word.id}`}>
                    <h2 className="text-xl font-semibold text-accent-yy">{word.english}</h2>
                    <p className="text-xl ">{word.translation}</p>
                </Link>
            </li>
            ))}
        </ul>
        </>
    )
}

export default AllWords;