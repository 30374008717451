import {
    Link,
} from "react-router-dom";

import LogoWCC from "assets/logo-wcclp-inverted.png";
import LogoILA from "assets/logo-ila.png";

function Acknowledgement() {
    return (
        <div className="mx-4 md:mx-auto md:max-w-2xl">

            <img src={LogoWCC} height={80} width={80} alt="WCC Logo mark" />
            <p className="my-10 font-bold text-gray-400">Wiray Ngiyang, Wiray Mayiny<br />No Language, No People<br />
                &mdash; Uncle Stan Grant & Aunty Flo Grant</p>

            <p className="text-center text-gray-400">Language content spoken by</p>
            <p className="mb-10 text-center">Dr. Stan Grant<br />Elizabeth Grant<br />Midnight Brydon</p>

            <p className="mb-4 text-gray-400">
                All information in this application is based on the work of Dr. Stan Grant and Dr. John Rudder. The majority of their work can be found in "A New Wiradjuri Dictionary"
            </p>
            <p className="mb-4 text-gray-400">
                We acknowledge the support and guidance of Wiradjuri elders (both past and present) in maintaining Traditional Values, Language and Culture.
            </p>

            <p className="mb-4 text-gray-400">
                Resource created by WCC Language Program with support from the Australian Government’s Indigenous Languages and Arts program.        </p>
            <img src={LogoILA} width={350} height={92} className="mx-auto" alt="Logo ILA" />
            <p className="mb-4 text-gray-400">Supported through the Australia Government's Indigenous Language and Arts program.</p>



            <Link to="menu" className="block w-full py-4 mt-10 font-bold text-center rounded-lg bg-accent-wiradjuri">
                Continue
            </Link>
        </div>
    )
}

export default Acknowledgement;