import {
    Link,
    Form
} from 'react-router-dom';

import {
    QueryClient,
} from 'react-query';

import {
    LanguageResponse,
} from 'api/models';

import {
    useGetAllLanguages,
    getGetAllLanguagesQueryKey
} from 'api/language/language';

export const loader =
    (queryClient: QueryClient) =>
        async () => {
            return (
                queryClient.getQueriesData(getGetAllLanguagesQueryKey()) ??
                (await queryClient.fetchQuery(getGetAllLanguagesQueryKey()))
            );
        }

function LanguageAdminContainer() {

    const {
        data: languages,
        refetch
    } = useGetAllLanguages();

    return (
        <div className="p-10">
            <div className="flex items-center justify-between mb-4">
                <h1 className="mb-4 text-4xl font-bold">Languages</h1>
                <Link to="/admin/languages/new" className="p-4 text-white bg-green-700 rounded-sm">New Language</Link>
            </div>
            <ul className="max-w-2xl mx-auto overflow-y-auto text-gray-700">
                {languages?.data.map((language: LanguageResponse) => (
                    <li key={language.id} className="flex items-center justify-between mb-4">

                        <div className="flex items-center justify-between mb-4">
                            {language.name}
                        </div>
                        <nav className="grid items-center grid-cols-2 text-center">
                            <Form
                                method="post"
                                action={`${language.id}/destroy`}
                                onSubmit={(event) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (!confirm("Please confirm you want to delete this record.")) {
                                        event.preventDefault();
                                    }
                                }}>
                                <button type="submit" className="text-red-400 hover:underline hover:text-red-600"
                                >Delete</button>
                            </Form>

                            <Link
                                className="rounded-md ml-8 bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                to={`${language.id}/edit`}>
                                Edit
                            </Link>
                        </nav>
                    </li>
                ))}
            </ul>
        </div >
    );
}

export default LanguageAdminContainer;