import {
  Fragment
} from 'react';
import {
  Link as RouterLink,
  useParams
} from 'react-router-dom';
import {
  Popover,
  Transition
} from '@headlessui/react';
import {
  UserCircleIcon,
  ChevronDownIcon
} from '@heroicons/react/solid';

import {
  parseISO
} from 'date-fns';

import {
  useGetMe
} from 'api/auth/auth';

import {
  useGetLanguageById
} from "api/language/language";
import { LanguageResponse } from 'api/models';

function AppHeader() {

  // Made available by orval
  const { data: user } = useGetMe();

  const { id }: any = useParams();

  const {
    data: language
  } = useGetLanguageById(id);


  const LanguageSelector = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md  px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="text-3xl">{language?.data ? language?.data.name : "Guyulgang"}</span>
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-6 w-6 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/3 left-10 sm:px-0 lg:max-w-2xl">
                <div className="overflow-hidden text-black rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <ul className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                  {user?.data.languages && user?.data.languages
                    .map((language: LanguageResponse) => (
                        <li
                          key={language.id}
                          className="p-4"
                        >
                          <RouterLink className="flex" to={`/guyulgang/${language.id}`}>
                            <img src={require("assets/icon-wcc-dictionary.png")}
                              alt={`${language.name} Dictionary`}
                              className="rounded-xl"
                              width={48}
                              height={48}
                            />
                            <div className="ml-2">
                              <h2 className="font-bold">{language.name}</h2>
                              <span className="text-sm">{parseISO(language.updatedAt).toLocaleString()}</span>
                            </div>
                          </RouterLink>
                        </li>))}
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const UserProfile = () => {
    return (
      <Popover className="relative">
        <Popover.Button>
          <UserCircleIcon
            className="w-10 h-10 pr-2" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-10 sm:px-0 lg:max-w-3xl">
            <div className="overflow-hidden text-black rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                <RouterLink to="/guyulgang/user-profile">User Profile</RouterLink>
                <RouterLink to="/admin/users">Manage Access</RouterLink>
                <RouterLink to="/admin/languages">Manage Languages</RouterLink>
                <RouterLink to="/auth/logout">Logout</RouterLink>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  };

  return (
    <header className="flex items-center justify-between min-w-full p-4">
      <RouterLink to="/guyulgang/languages">
        <img
          src={require("assets/logo-wcclp-inverted.png")}
          alt="Wiradjuri Condobolin Corporation Language Program Logo Mark"
          width={64}
          height={64} />
      </RouterLink>
      <LanguageSelector />
      <UserProfile />
    </header>);
}

export default AppHeader;