import {
    redirect, Form, useParams,
} from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';

import type { LanguageRequest, LanguageResponse } from 'api/models';

import {
    updateLanguage,
    getGetLanguageByIdQueryKey,
    getLanguageById,
    useGetLanguageById,
} from 'api/language/language';


export const loader =
    (queryClient: QueryClient) =>
        async ({ request, params }: any) => {
            const query = getGetLanguageByIdQueryKey(params.id);
            return (queryClient.getQueriesData(query) ??
                (await queryClient.fetchQuery(query)));
        };


export const action = (queryClient: QueryClient) =>
    async ({ request, params }: any) => {
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await updateLanguage(params.id, updates as LanguageRequest);
        return redirect('../');
    };

function LanguageEditor() {

    const { id }: any = useParams();

    // Made available by orval
    const { data: language } = useGetLanguageById(id);

    return (
        <div>
            <Form
                className="flex flex-col max-w-md mx-auto"
                method="post" id="update-user">
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="name"
                >
                    Language group
                    <input
                        placeholder="e.g Wiradjuri"
                        aria-label="Language group"
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        name="name"
                        defaultValue={language?.data.name}
                    />
                </label>
                <button
                    className="inline-flex items-center px-6 py-3 mt-6 text-base font-medium text-center text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    type="submit">Save</button>
            </Form>
        </div>
    );
}

export default LanguageEditor;