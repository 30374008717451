import {
    Link
} from "react-router-dom";

import YortaYorta from "data/YortaYorta.json";

function Traditional() {

    /**
     * Returns an array of words with images
     * flatten the array of categories and then filter for ones with images
     * @returns Array
     */
    const wordsWithImages = YortaYorta.categories
        .map(category => category.entries)
        .flat(1)
        .filter((e:any) => e.image!=null)

    return (
        <div className="mx-4 md:mx-auto md:max-w-2xl">
            <h1 className="p-4 mb-6 text-2xl font-bold md:p-0">
                Traditional Search
            </h1>
            <ul className="grid grid-cols-3 gap-4">
                {wordsWithImages.map((word:any) => (
                    <li key={word.id} className="w-34 h-34">
                    <Link to={`../word/${word.id}`}>
                        <img 
                        className="rounded-lg"
                        src={`https://media.guyulgang.com/yorta-yorta/20211108/images/${word.image}`} 
                        height={200}
                        width={200}
                        alt={word.id}/>
                    </Link>
                    </li>
                ))}
            </ul>
        </div>

    )
}

export default Traditional;