import { Link } from 'react-router-dom';

import {
    QueryClient,
} from 'react-query';

import {
    useGetMe,
    getGetMeQueryKey
} from "api/auth/auth";

export const loader = (queryClient: QueryClient) => 
async() => {
    return(
        queryClient.getQueryData(getGetMeQueryKey())??
        await queryClient.fetchQuery(getGetMeQueryKey())
    );
};

function Menu() {

    const {
        data: me
    } = useGetMe();

    return(
    <div className="flex flex-col items-center justify-center h-screen">
    <h2 className="mb-2 text-4xl font-semibold text-center">Welcome {me?.data.firstName} {me?.data.lastName}</h2>
    <p className="mb-16 text-center text-gray-300 ">
        Please click through to the platform you wish to use.
    </p>
    <section className="grid grid-cols-2 gap-20 pb-16 mx-4 text-center lg:mx-auto lg:grid-cols-2 lg:max-w-screen-lg">
        <div className="flex flex-col items-center justify-center">
            <h3 className="mb-6 text-2xl font-semibold">Guyulgang</h3>
            <Link className="shadow-2xl shadow-white rounded-3xl"
            to="/guyulgang/languages">
            <img 
            className="p-2 border border-gray-900 rounded-3xl"
            height={128}
            width={128}
            src={require("assets/icon-guyulgang-inverted.png")} 
            alt="Logo - guyulgang"/>
            </Link>
        </div>
        <div className="flex flex-col items-center justify-center">
            <h3 className="mb-6 text-2xl font-semibold">Dhaga</h3>
            <Link className="shadow-2xl shadow-white rounded-3xl" 
            to="/dhaga/">
            <img 
            className="border border-gray-900 rounded-3xl"
            height={128}
            width={128}
            src={require("assets/icon-dhaga.png")}
            alt="WCC - Dhaga"
            placeholder="tracedSVG"/>
            </Link>
        </div>
    </section>
    </div>);
}

export default Menu;