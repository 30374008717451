import { redirect, Form, useParams } from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';

import {
    useGetUserById,
    getGetUserByIdQueryKey,
    updateUser,
} from 'api/user/user';

import {
    useGetAllLanguages,

} from "api/language/language"

import { UserRequest } from 'api/models';
import { Helmet } from 'react-helmet';

export const loader =
    (queryClient: QueryClient) =>
        async ({ request, params }: any) => {
            const query = getGetUserByIdQueryKey(params.id);
            return (queryClient.getQueriesData(query) ??
                (await queryClient.fetchQuery(query)));
        };

export const action = (queryClient: QueryClient) =>
    async ({ request, params }: any) => {
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        // Get multiple value
        updates.languageIds = formData.getAll('languageIds');
        await updateUser(params.id, updates as UserRequest);
        queryClient.invalidateQueries(getGetUserByIdQueryKey(params.id));
        return redirect('../');
    };

function UserEditor() {

    const { id }: any = useParams();

    // Made available by orval
    const { data: user } = useGetUserById(id);
    const { data: languages } = useGetAllLanguages();

    return (
        <div>
            <Helmet>
                <title>Edit User</title>
            </Helmet>
            <Form
                className="flex flex-col max-w-md mx-auto"
                method="patch" id="update-user">
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="firstName"
                >
                    First name
                    <input
                        autoFocus
                        placeholder="First"
                        aria-label="First name"
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        name="firstName"
                        defaultValue={user?.data.firstName ? user?.data.firstName:""}
                    />
                </label>
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="lastName"
                >
                    Last name
                    <input
                        placeholder="Last"
                        aria-label="Last name"
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        name="lastName"
                        defaultValue={user?.data.lastName ? user?.data.lastName:""}
                    />
                </label>

                <label
                    className="mt-8 text-gray-500"
                    htmlFor="email"
                >
                    Email address
                    <input
                        placeholder="Email"
                        aria-label="Email"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        type="email"
                        name="email"
                        defaultValue={user?.data.email}
                    />

                </label>
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="mobileNumber"
                >
                    Mobile number
                    <input
                        placeholder="Mobile"
                        aria-label="Mobile"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        type="phone"
                        name="mobileNumber"
                        defaultValue={user?.data.mobileNumber ? user?.data.mobileNumber:""}
                    />
                </label>
                {languages?.data.map((language, index) => (
                    <div key={index} className="my-2">
                        <label>
                            <input
                                className="ml-2"
                                name="languageIds"
                                id={language.id}
                                type="checkbox"
                                value={language.id}
                                defaultChecked={user?.data.languages?.some((userLanguage) => userLanguage.id === language.id)}
                                // Add your handling function for checkbox state
                                // onChange={handleCheckboxChange}
                            />
                            <span className="ml-2">{language.name}</span>
                        </label>
                    </div>
                ))}   
                <button
                    className="inline-flex items-center px-6 py-3 mt-6 text-base font-medium text-center text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    type="submit">Save</button>
            </Form>
        </div>
    );
}

export default UserEditor;