import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';

import { useQueryClient } from 'react-query';

import {
  useGetWords,
  useGetCategoriesWithLimits,
  getGetWordsWithLimitsQueryKey,
} from 'api/language/language';
import { WordSummaryResponse, CategoryResponse, } from 'api/models';
import { use } from 'i18next';

function WordNavigation() {

  const { id, wordId }: any = useParams();

  const queryClient = useQueryClient();

  const [keywords, setKeywords] = useState('');
  const [hasImage, setHasImage] = useState(false);
  const [hasAudio, setHasAudio] = useState(false);
  const [categoryId, setCategoryId] = useState<string | null>(null);

  // Get a list of words
  const {
    data: words,
    isFetching,
  } = useGetWords(id, {
    keywords: keywords,
    has_image: hasImage,
    has_audio: hasAudio,
    category_id: categoryId ? categoryId : undefined,
  });

  const {
    data: categories,
  } = useGetCategoriesWithLimits(id, {
    offset: 0,
    limit: 100,
  });

  useEffect(() => {
    queryClient.invalidateQueries(getGetWordsWithLimitsQueryKey(id));
  }, [id, keywords, hasImage, hasAudio, categoryId, queryClient]);

  return (
    <nav className="sticky top-0 flow-root overflow-visible overflow-y-scroll border-r border-r-gray-200">
      <div className="sticky top-0 z-20 flex flex-col px-4 py-2 border-b border-black bg-gray-50">
        <input
          type="text"
          placeholder="Search ..."
          onChange={(e) => setKeywords(e.target.value)}
          className="w-full px-4 py-2 border border-gray-400 rounded-full" />
        <div className="flex justify-between my-2">
          <label className="flex items-center">
            <input name="hasImage" type="checkbox" className="mx-2 my-2" onChange={() => { setHasImage(!hasImage) }} />
            Has Image
          </label>
          <label className="flex items-center">
            <input name="hasAudio" type="checkbox" className="mx-2 my-2" onChange={() => { setHasAudio(!hasAudio) }} />
            Has Audio
          </label>
        </div>
        <label className="flex items-center justify-between my-4 text-sm font-medium text-gray-700">
          <span>Category</span>
          <select role="list" className="p-2 border border-gray-300 rounded-sm" onChange={(event) => {
            setCategoryId(event.target.value)
          }}>
            <option className="flex items-center my-1" value="">All</option>
            {categories?.data.map((category: CategoryResponse) => (
              <option key={category.id} className="flex items-center my-1" value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </label>
        <p className="text-sm text-center text-gray-500">Showing {words?.data.length} words</p>
      </div>
      {isFetching && (
        <div className="absolute inset-0 z-10 flex items-center justify-center font-semibold text-gray-600 bg-blue-50">
          Fetching words ...
        </div>
      )}
      <ul className="relative z-0 overflow-y-scroll divide-y divide-gray-200">
        {words?.data.map((word: WordSummaryResponse) => (
          <li
            key={word.id}
            className={`relative flex items-center px-6 py-5 space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 ${wordId && wordId === word.id ? 'bg-blue-50' : ''}`}>
            <div className="flex-1 min-w-0">
              <Link to={`../${id}/words/${word.id}/edit`} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{word.english}</p>
                <p className="text-sm text-gray-500 truncate">{word.indigenous}</p>
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default WordNavigation;