import { redirect } from 'react-router-dom';
import { deleteLanguage } from 'api/language/language';
import { QueryClient } from 'react-query';

import { getGetAllLanguagesQueryKey } from 'api/language/language';

export const action =
    (queryClient: QueryClient) =>
        async ({ params }: any) => {
            await deleteLanguage(params.id);
            queryClient.invalidateQueries(getGetAllLanguagesQueryKey());
            return redirect('../');
        }