/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * guyulgang-api
 * 
        This project provides a reference Python API built using FastAPI, the 
        aim of the project is:

        - To maintain a good know source of habits
        - Demonstrate how applications are meant to be put together at Anomaly
        - Democratize design of robust API    
    
 * OpenAPI spec version: 0.16.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  SignupResponse,
  HTTPValidationError,
  SignupRequest,
  ResetPasswordRequest,
  OTPTriggerEmailRequest,
  OTPTriggerSMSRequest,
  InitiateResetPasswordRequest,
  VerifyAccountRequest,
  OTPVerifyRequest,
  Token,
  BodyLoginForAuthToken,
  UserDetailResponse,
  UpdateUserRequest
} from '.././models'


/**
 * Sign up the user using email and password

The general sign up for uses a email, password and first
and last names to create a user. The handler will check
to see if the user already exists and if not, create the
user and return a success response.
 * @summary Signup User
 */
export const signupUser = (
    signupRequest: SignupRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SignupResponse>> => {
    return axios.post(
      `/signup`,
      signupRequest,options
    );
  }



export const getSignupUserMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signupUser>>, TError,{data: SignupRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof signupUser>>, TError,{data: SignupRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof signupUser>>, {data: SignupRequest}> = (props) => {
          const {data} = props ?? {};

          return  signupUser(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SignupUserMutationResult = NonNullable<Awaited<ReturnType<typeof signupUser>>>
    export type SignupUserMutationBody = SignupRequest
    export type SignupUserMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Signup User
 */
export const useSignupUser = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signupUser>>, TError,{data: SignupRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSignupUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Reset Password
 */
export const resetPassword = (
    resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/password/reset`,
      resetPasswordRequest,options
    );
  }



export const getResetPasswordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, {data: ResetPasswordRequest}> = (props) => {
          const {data} = props ?? {};

          return  resetPassword(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>
    export type ResetPasswordMutationBody = ResetPasswordRequest
    export type ResetPasswordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Reset Password
 */
export const useResetPassword = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getResetPasswordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Attempt to authenticate a user and issue JWT token

The user has provided us their email address and we will
attempt to authenticate them via OTP.
 * @summary Initiate Otp Email
 */
export const initiateOtpEmail = (
    oTPTriggerEmailRequest: OTPTriggerEmailRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/initiate/otp/email`,
      oTPTriggerEmailRequest,options
    );
  }



export const getInitiateOtpEmailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateOtpEmail>>, TError,{data: OTPTriggerEmailRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof initiateOtpEmail>>, TError,{data: OTPTriggerEmailRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof initiateOtpEmail>>, {data: OTPTriggerEmailRequest}> = (props) => {
          const {data} = props ?? {};

          return  initiateOtpEmail(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InitiateOtpEmailMutationResult = NonNullable<Awaited<ReturnType<typeof initiateOtpEmail>>>
    export type InitiateOtpEmailMutationBody = OTPTriggerEmailRequest
    export type InitiateOtpEmailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Initiate Otp Email
 */
export const useInitiateOtpEmail = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateOtpEmail>>, TError,{data: OTPTriggerEmailRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getInitiateOtpEmailMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Attempt to authenticate a user and issue JWT token

The user has provided a mobile number and we will text them
their OTP and let them login.
 * @summary Initiate Otp Sms
 */
export const initiateOtpSms = (
    oTPTriggerSMSRequest: OTPTriggerSMSRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/initiate/otp/sms`,
      oTPTriggerSMSRequest,options
    );
  }



export const getInitiateOtpSmsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateOtpSms>>, TError,{data: OTPTriggerSMSRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof initiateOtpSms>>, TError,{data: OTPTriggerSMSRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof initiateOtpSms>>, {data: OTPTriggerSMSRequest}> = (props) => {
          const {data} = props ?? {};

          return  initiateOtpSms(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InitiateOtpSmsMutationResult = NonNullable<Awaited<ReturnType<typeof initiateOtpSms>>>
    export type InitiateOtpSmsMutationBody = OTPTriggerSMSRequest
    export type InitiateOtpSmsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Initiate Otp Sms
 */
export const useInitiateOtpSms = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateOtpSms>>, TError,{data: OTPTriggerSMSRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getInitiateOtpSmsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Initiate Password Reset
 */
export const initiatePasswordReset = (
    initiateResetPasswordRequest: InitiateResetPasswordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/initiate/password/reset`,
      initiateResetPasswordRequest,options
    );
  }



export const getInitiatePasswordResetMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiatePasswordReset>>, TError,{data: InitiateResetPasswordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof initiatePasswordReset>>, TError,{data: InitiateResetPasswordRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof initiatePasswordReset>>, {data: InitiateResetPasswordRequest}> = (props) => {
          const {data} = props ?? {};

          return  initiatePasswordReset(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InitiatePasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof initiatePasswordReset>>>
    export type InitiatePasswordResetMutationBody = InitiateResetPasswordRequest
    export type InitiatePasswordResetMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Initiate Password Reset
 */
export const useInitiatePasswordReset = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiatePasswordReset>>, TError,{data: InitiateResetPasswordRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getInitiatePasswordResetMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Initiate Verification Email
 */
export const initiateVerificationEmail = (
    initiateResetPasswordRequest: InitiateResetPasswordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/initiate/verify`,
      initiateResetPasswordRequest,options
    );
  }



export const getInitiateVerificationEmailMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateVerificationEmail>>, TError,{data: InitiateResetPasswordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof initiateVerificationEmail>>, TError,{data: InitiateResetPasswordRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof initiateVerificationEmail>>, {data: InitiateResetPasswordRequest}> = (props) => {
          const {data} = props ?? {};

          return  initiateVerificationEmail(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type InitiateVerificationEmailMutationResult = NonNullable<Awaited<ReturnType<typeof initiateVerificationEmail>>>
    export type InitiateVerificationEmailMutationBody = InitiateResetPasswordRequest
    export type InitiateVerificationEmailMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Initiate Verification Email
 */
export const useInitiateVerificationEmail = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateVerificationEmail>>, TError,{data: InitiateResetPasswordRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getInitiateVerificationEmailMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Verify an account using a one time token

The signup process would have emailed the user a one time activation
token, pass this token to the user object and we can ask the account
to be set as verified.

If the token is invalid, or was never generated an obscure error 
message is to be sent back to the client, so we don't reveal that
the token or accounts status is valid
 * @summary Verify User
 */
export const verifyUser = (
    verifyAccountRequest: VerifyAccountRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/verify/account`,
      verifyAccountRequest,options
    );
  }



export const getVerifyUserMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyUser>>, TError,{data: VerifyAccountRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof verifyUser>>, TError,{data: VerifyAccountRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyUser>>, {data: VerifyAccountRequest}> = (props) => {
          const {data} = props ?? {};

          return  verifyUser(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type VerifyUserMutationResult = NonNullable<Awaited<ReturnType<typeof verifyUser>>>
    export type VerifyUserMutationBody = VerifyAccountRequest
    export type VerifyUserMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Verify User
 */
export const useVerifyUser = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyUser>>, TError,{data: VerifyAccountRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getVerifyUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Attempt to authenticate a user and issue JWT token
 * @summary Verify Otp
 */
export const verifyOtp = (
    oTPVerifyRequest: OTPVerifyRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/verify/otp`,
      oTPVerifyRequest,options
    );
  }



export const getVerifyOtpMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyOtp>>, TError,{data: OTPVerifyRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof verifyOtp>>, TError,{data: OTPVerifyRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyOtp>>, {data: OTPVerifyRequest}> = (props) => {
          const {data} = props ?? {};

          return  verifyOtp(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type VerifyOtpMutationResult = NonNullable<Awaited<ReturnType<typeof verifyOtp>>>
    export type VerifyOtpMutationBody = OTPVerifyRequest
    export type VerifyOtpMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Verify Otp
 */
export const useVerifyOtp = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyOtp>>, TError,{data: OTPVerifyRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getVerifyOtpMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Attempt to authenticate a user and issue JWT token

If the user does not exists or the password is incorrect
then an exception is raised which returns a 4xx response.
 * @summary Provides an endpoint for login via email and password
 */
export const loginForAuthToken = (
    bodyLoginForAuthToken: BodyLoginForAuthToken, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Token>> => {const formUrlEncoded = new URLSearchParams();
if(bodyLoginForAuthToken.grant_type !== undefined && bodyLoginForAuthToken.grant_type) {
 formUrlEncoded.append('grant_type', bodyLoginForAuthToken.grant_type)
 }
formUrlEncoded.append('username', bodyLoginForAuthToken.username)
formUrlEncoded.append('password', bodyLoginForAuthToken.password)
if(bodyLoginForAuthToken.scope !== undefined) {
 formUrlEncoded.append('scope', bodyLoginForAuthToken.scope)
 }
if(bodyLoginForAuthToken.client_id !== undefined && bodyLoginForAuthToken.client_id) {
 formUrlEncoded.append('client_id', bodyLoginForAuthToken.client_id)
 }
if(bodyLoginForAuthToken.client_secret !== undefined && bodyLoginForAuthToken.client_secret) {
 formUrlEncoded.append('client_secret', bodyLoginForAuthToken.client_secret)
 }

    return axios.post(
      `/token`,
      formUrlEncoded,options
    );
  }



export const getLoginForAuthTokenMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginForAuthToken>>, TError,{data: BodyLoginForAuthToken}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof loginForAuthToken>>, TError,{data: BodyLoginForAuthToken}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginForAuthToken>>, {data: BodyLoginForAuthToken}> = (props) => {
          const {data} = props ?? {};

          return  loginForAuthToken(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LoginForAuthTokenMutationResult = NonNullable<Awaited<ReturnType<typeof loginForAuthToken>>>
    export type LoginForAuthTokenMutationBody = BodyLoginForAuthToken
    export type LoginForAuthTokenMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Provides an endpoint for login via email and password
 */
export const useLoginForAuthToken = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginForAuthToken>>, TError,{data: BodyLoginForAuthToken}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getLoginForAuthTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Provides a refresh token for the JWT session.

There must be a currently authenticated user for the refresh
to work, otherwise an exception is raised.
 * @summary  Provides an endpoint for refreshing the JWT token
 */
export const refreshJwtToken = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Token>> => {
    return axios.post(
      `/refresh`,undefined,options
    );
  }



export const getRefreshJwtTokenMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshJwtToken>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof refreshJwtToken>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshJwtToken>>, TVariables> = () => {
          

          return  refreshJwtToken(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RefreshJwtTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshJwtToken>>>
    
    export type RefreshJwtTokenMutationError = AxiosError<unknown>

    /**
 * @summary  Provides an endpoint for refreshing the JWT token
 */
export const useRefreshJwtToken = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshJwtToken>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRefreshJwtTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Ends a users session

Essentially invalidates a JWT token and then proceeds returns
a success response.
 * @summary  Provides an endpoint for logging out the user
 */
export const logoutUser = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/logout`,undefined,options
    );
  }



export const getLogoutUserMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logoutUser>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof logoutUser>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof logoutUser>>, TVariables> = () => {
          

          return  logoutUser(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LogoutUserMutationResult = NonNullable<Awaited<ReturnType<typeof logoutUser>>>
    
    export type LogoutUserMutationError = AxiosError<unknown>

    /**
 * @summary  Provides an endpoint for logging out the user
 */
export const useLogoutUser = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logoutUser>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getLogoutUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Get the currently logged in user or myself

This endpoint will return the currently logged in user or raise
and exception if the user is not logged in.
 * @summary Get Me
 */
export const getMe = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserDetailResponse>> => {
    return axios.get(
      `/me`,options
    );
  }


export const getGetMeQueryKey = () => [`/me`] as const;
  

    
export const getGetMeQueryOptions = <TData = Awaited<ReturnType<typeof getMe>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMeQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) => getMe({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeQueryError = AxiosError<unknown>

/**
 * @summary Get Me
 */
export const useGetMe = <TData = Awaited<ReturnType<typeof getMe>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Get the currently logged in user or myself

This endpoint will return the currently logged in user or raise
and exception if the user is not logged in.
 * @summary Update Me
 */
export const updateMe = (
    updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserDetailResponse>> => {
    return axios.patch(
      `/me`,
      updateUserRequest,options
    );
  }



export const getUpdateMeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMe>>, TError,{data: UpdateUserRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateMe>>, TError,{data: UpdateUserRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMe>>, {data: UpdateUserRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateMe(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateMeMutationResult = NonNullable<Awaited<ReturnType<typeof updateMe>>>
    export type UpdateMeMutationBody = UpdateUserRequest
    export type UpdateMeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Me
 */
export const useUpdateMe = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMe>>, TError,{data: UpdateUserRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateMeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    