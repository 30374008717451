import { redirect, Form, useParams } from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';

import {
    useGetMe,
    getGetMeQueryKey,
    updateMe,
} from 'api/auth/auth';

import { UpdateUserRequest } from 'api/models';
import { Helmet } from 'react-helmet';

export const loader = (queryClient: QueryClient) => 
async() => {
    return(
        queryClient.getQueryData(getGetMeQueryKey())??
        await queryClient.fetchQuery(getGetMeQueryKey())
    );
};

export const action = (queryClient: QueryClient) =>
    async ({ request, params }: any) => {
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await updateMe(updates as UpdateUserRequest);
        return redirect('../');
    };


/**
 * 
 * @returns {JSX.Element}
 */
function UserProfile() {

    // Made available by orval
    const { data: user } = useGetMe();

    return (
        <div>
            <Helmet>
                <title>Your profile</title>
            </Helmet>
            <Form
                className="flex flex-col max-w-md mx-auto"
                method="patch" id="update-me">
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="firstName"
                >
                    First name
                    <input
                        autoFocus
                        placeholder="First"
                        aria-label="First name"
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        name="firstName"
                        defaultValue={user?.data.firstName ? user?.data.firstName:""}
                    />
                </label>
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="lastName"
                >
                    Last name
                    <input
                        placeholder="Last"
                        aria-label="Last name"
                        type="text"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        name="lastName"
                        defaultValue={user?.data.lastName ? user?.data.lastName:""}
                    />
                </label>

                <label
                    className="mt-8 text-gray-500"
                    htmlFor="email"
                >
                    Email address
                    <input
                        placeholder="Email"
                        aria-label="Email"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        type="email"
                        name="email"
                        defaultValue={user?.data.email}
                    />

                </label>
                <label
                    className="mt-8 text-gray-500"
                    htmlFor="mobileNumber"
                >
                    Mobile number
                    <input
                        placeholder="Mobile"
                        aria-label="Mobile"
                        className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"
                        type="phone"
                        name="mobileNumber"
                        defaultValue={user?.data.mobileNumber ? user?.data.mobileNumber:""}
                    />

                </label>
                <button
                    className="inline-flex items-center px-6 py-3 mt-6 text-base font-medium text-center text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    type="submit">Update</button>
            </Form>
        </div>
    );
}

export default UserProfile;