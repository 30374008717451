import {
    Link,
    Form
} from 'react-router-dom';

import {
    QueryClient,
} from 'react-query';

import {
    UserResponse,
} from 'api/models';

import {
    useGetUsersWithLimits,
    getGetUsersWithLimitsQueryKey,
} from 'api/user/user';

/**
 * Loader that the router uses to get content, this
 * returns an async function that it can use.
 * 
 * react-query takes the key as an array, which would
 * represent the collection as well as individual items.
 * 
 * getGetUsersWithLimitsQueryKey is generated by orval
 * which provides a construct similar to what's reference
 * in the documentation.
 * 
 * const contactListQuery = (q) => ({
*    queryKey: ["contacts", "list", q ?? "all"],
*    queryFn: () => getContacts(q),
*  })
 * 
 * 
 * @param queryClient 
 * @returns function that returns a promise
 */
export const loader =
    (queryClient: QueryClient) =>
        async () => {
            return (
                queryClient.getQueriesData(getGetUsersWithLimitsQueryKey()) ??
                (await queryClient.fetchQuery(getGetUsersWithLimitsQueryKey({
                    offset: 0,
                    limit: 100
                })))
            );
        }

function AdminContainer() {

    const {
        data: users,
        refetch
    } = useGetUsersWithLimits();

    return (
        <div className="p-10">
            <div className="flex items-center justify-between mb-4">
                <h1 className="mb-4 text-4xl font-bold">Users</h1>
                <Link to="/admin/users/new" className="p-4 text-white bg-green-700 rounded-sm">New User</Link>
            </div>
            <ul className="max-w-2xl mx-auto overflow-y-auto text-gray-700">
                {users?.data.map((user: UserResponse) => (
                    <li key={user.id} className="flex items-center justify-between mb-4">

                        <div className="flex items-center justify-between mb-4">
                            {user.firstName} {user.lastName}
                        </div>
                        <nav className="grid items-center grid-cols-2 text-center">
                            <Form
                                method="post"
                                action={`${user.id}/destroy`}
                                onSubmit={(event) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (!confirm("Please confirm you want to delete this record.")) {
                                        event.preventDefault();
                                    }
                                }}>
                                <button type="submit" className="text-red-400 hover:underline hover:text-red-600"
                                >Delete</button>
                            </Form>

                            <Link
                                className="rounded-md ml-8 bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                to={`${user.id}/edit`}>
                                Edit
                            </Link>
                        </nav>
                    </li>
                ))}
            </ul>
        </div >
    );
}

export default AdminContainer;