import {
    Link,
} from "react-router-dom";

import LogoWCC from "assets/logo-blac.png";
import LogoILA from "assets/logo-ila.png";

function Acknowledgement() {
    return (
        <div className="mx-4 md:mx-auto md:max-w-2xl">

            <img src={LogoWCC} height={80} width={80} alt="WCC Logo mark" />

            <p className="text-center text-gray-400">Spoken by</p>
            <p className="mb-10 text-center">Members of the<br />Barngarla community.</p>

            <p className="mb-4 text-gray-400">
                We acknowledge the support and guidance of elders (both past and present) in maintaining Traditional values, Language and Culture            </p>
            <p className="mb-4 text-gray-400">
            </p>

            <p className="mb-4 text-gray-400">
                Based on the hard work of Barngarla Language Advisory Committee in conjunction with Ghil'ad Zuckermann
            </p>
            <img src={LogoILA} width={350} height={92} className="mx-auto" />
            <p className="mb-4 text-gray-400">Supported through the Australia Government's Indigenous Language and Arts program.</p>



            <Link to="menu" className="block w-full py-4 mt-10 font-bold text-center rounded-lg bg-accent-barngarla">
                Continue
            </Link>
        </div>
    )
}

export default Acknowledgement;