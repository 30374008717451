import {
    Link,
    useParams
} from "react-router-dom";

import Jawoyn from "data/Jawoyn.json";


function WordsByLetter() {

    const { letter } = useParams();

    const wordsByLetter = Jawoyn.categories
        .map(category => category.entries)
        .flat(1)
        .sort((a: any, b: any) => a.english.localeCompare(b.english))
        .reduce((r: any, e: any) => {
            let group = e.english[0].toUpperCase();
            if (!r[group]) r[group] = { group, words: [e] };
            else r[group].words.push(e);
            return r;
        }, {});

    return (
        <>
            <h1 className="p-4 mx-auto mb-4 text-2xl font-bold md:max-w-2xl md:p-0">
                Words starting with {letter}
            </h1>
            <ul className="mx-auto divide-y-2 divide-gray-700 md:max-w-2xl">
                {letter && wordsByLetter[letter].words.map((word: any) => (
                    <li key={word.id} className="px-2 py-4">
                        <Link to={`../word/${word.id}`}>
                            <h2 className="text-xl font-semibold text-accent-jawoyn">{word.english}</h2>
                            <p className="text-xl ">{word.translation}</p>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default WordsByLetter;