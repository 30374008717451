function Videos() {
    return (
        <div className="flex flex-col items-center mx-4 md:mx-auto md:max-w-2xl">
            <div>
                <iframe 
                    src="https://player.vimeo.com/video/549116085?h=a3e1260c77"
                    width="640" 
                    height="427" 
                    allow="autoplay; fullscreen; picture-in-picture">
                    </iframe>
                <p className="py-2 text-center">
                    <a href="https://vimeo.com/549116085">
                    yamutj bultjubul</a>    
                </p>
            </div>

            <div>
                <iframe 
                    src="https://player.vimeo.com/video/643722967?h=362ba43cfa"
                    width="640" 
                    height="427" 
                    allow="autoplay; fullscreen; picture-in-picture">
                    </iframe>
                <p className="py-2 text-center">
                    <a href="https://vimeo.com/643722967">
                        BIGARRUMDJA YAMA How To Play</a>    
                </p>
            </div>

            <div>
                <iframe 
                    src="https://player.vimeo.com/video/754149351?h=9eaca7a0dd"
                    width="640" 
                    height="427" 
                    allow="autoplay; fullscreen; picture-in-picture">
                    </iframe>
                <p className="py-2 text-center">
                    <a href="https://vimeo.com/754149351">
                    Nyuwandan Bawu Ina Yorta Yorta - Sound Examples</a>    
                </p>
            </div>

        </div>
    )
}

export default Videos;