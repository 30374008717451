import {
    Link
} from "react-router-dom";

import YortaYorta from "data/YortaYorta.json";

function Favourites() {

    const favouriteWords = YortaYorta.categories
        .map(category => category.entries)
        .flat(1)
        .filter((e:any) => localStorage.getItem(e.id) !== null)

    return (
        <>
        <h1 className="p-4 mx-auto mb-4 text-2xl font-bold md:max-w-2xl md:p-0">
            Favourites
        </h1>
        <ul className="grid max-w-md grid-cols-1 mx-auto mb-6 divide-y-2 divide-gray-700 md:max-w-2xl">
            {favouriteWords.map((word:any) => (
                <li key={word.id} className="px-2 py-4">
                    <Link to={`../word/${word.id}`}>
                        <h2 className="text-xl font-semibold text-accent-yy">{word.english}</h2>
                        <p className="text-xl ">{word.translation}</p>
                    </Link>
                </li>
            ))}    
        </ul>
        </>
    )
}

export default Favourites;