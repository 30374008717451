import {
    Link
} from "react-router-dom";

import Barngarla from "data/Barngarla.json";

/**
 * Returns a view with a letter of 
 * @returns JSX.Element
 */
function Letters() {

    const wordsByLetter = Barngarla.categories
        .map(category => category.entries)
        .flat(1)
        .reduce((r: any, e: any) => {
            let group = e.english[0].toUpperCase();
            if (!r[group]) r[group] = { group, words: [e] };
            else r[group].words.push(e);
            return r;
        }, {});

    const rearrangedGroups = Object.values(wordsByLetter)
        .sort((a: any, b: any) => a.group.localeCompare(b.group));

    return (
        <>
            <h1 className="p-4 mx-auto mb-6 text-2xl font-bold md:max-w-2xl md:p-0">
                Words by Letter
            </h1>
            <ul className="grid grid-cols-2 mx-auto md:max-w-2xl gap-y-8 gap-x-4 md:grid-cols-4">
                {rearrangedGroups.map((group: any) => (
                    <li key={group.group} className="">
                        <Link to={`../letter/${group.group}/words`} className="flex flex-col items-center justify-between">
                            <h2 className="flex items-center justify-center w-20 h-20 text-4xl font-bold bg-black rounded-full text-accent-barngarla">
                                {group.group}
                            </h2>
                            <span className="mt-6">{group.words.length} words</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Letters;