import {
    Outlet
} from 'react-router-dom';


function CategoryContainer() {

    return(
        <div className="p-10 text-black bg-white">
            <Outlet />
        </div>
    );
}

export default CategoryContainer;