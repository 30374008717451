import type { LanguageResponse } from 'api/models';

import {
    parseISO
} from 'date-fns'

import {
    Link,
} from 'react-router-dom';

import {
    QueryClient,
} from 'react-query';

import {
    useGetMe,
    getGetMeQueryKey,
} from 'api/auth/auth';

export const loader = (queryClient: QueryClient) => 
async() => {
    return(
        queryClient.getQueryData(getGetMeQueryKey())??
        await queryClient.fetchQuery(getGetMeQueryKey())
    );
};

function LanguageChooser() {

    // Made available by orval
    const { data: user } = useGetMe();
    
    const localeOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    return (
        <div className="text-black">
            {user?.data.languages && user?.data.languages.length === 0 && (
                <div className="flex flex-col items-center justify-center h-full">
                    <h1 className="mt-20 text-2xl font-bold">You don't have any languages yet.</h1>
                </div>)}
            <ul className="grid grid-cols-3">
                {user?.data.languages && user?.data.languages
                    .map((language: LanguageResponse) => (
                        <li
                            key={language.id}
                            className="p-8"
                        >
                            <Link className="grid gap-3 grid-cols-[15%,85%]" to={`../${language.id}`}>
                                <div className="flex items-center justify-center bg-black rounded-lg">
                                    <p className="text-2xl font-bold text-white">
                                        {language.name.split(' ').map((word: string) => word[0]).join('')}
                                    </p>
                                </div>
                                <div className="ml-2">
                                    <h2 className="text-xl font-bold">
                                        {language.name}
                                    </h2>
                                    <p className="text-sm text-gray-500">Last updated</p>
                                    <span className="text-sm text-gray-600">{parseISO(language.updatedAt).toLocaleString()}</span>
                                </div>
                            </Link>
                        </li>))}
            </ul>
        </div>
    );

}

export default LanguageChooser;