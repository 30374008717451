import {
    useState
} from "react";

import {
    Link
} from "react-router-dom";

import Jawoyn from "data/Jawoyn.json";


function Search() {

    /**
     * Returns an array of words with images
     * flatten the array of categories and then filter for ones with images
     * @returns Array
     */
    const words = Jawoyn.categories
        .map(category => category.entries)
        .flat(1)


    const [searchResults, setSearchResults] = useState(Array());

    const filterWords = (keyword: string) => {
        if (keyword === "") {
            setSearchResults([]);
            return;
        }
        setSearchResults(words.filter((word: any) => {
            return word.english.toLowerCase().includes(keyword.toLowerCase()) || word.translation.toLowerCase().includes(keyword.toLowerCase())
        }));
    }

    return (
        <div className="mx-auto md:max-w-2xl">
            <h1 className="p-4 mb-6 text-2xl font-bold md:p-0">
                Search results
            </h1>
            <div>
                <input
                    onChange={(e) => filterWords(e.target.value)}
                    type="text" className="w-full px-4 py-2 mx-auto text-black border-2 border-gray-400 rounded-md" />
            </div>
            {searchResults.length === 0 && <p className="p-8 text-xl text-center">No words match your search</p>}
            <ul className="mx-auto mt-6 divide-y-2 divide-gray-700">
                {searchResults.map((word: any) => (
                    <li key={word.id} className="px-2 py-4">
                        <Link to={`../word/${word.id}`}>
                            <h2 className="text-xl font-semibold text-accent-jawoyn">{word.english}</h2>
                            <p className="text-xl ">{word.translation}</p>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Search;