import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

function Header() {
    return(
    <header className="flex justify-between min-w-full">
      <RouterLink to="/">
        <img
          src={require("assets/logo-wcclp-inverted.png")}
          alt="Wiradjuri Condobolin Corporation Language Program Logo Mark"
          width={64}
          height={64}/>
      </RouterLink>
      <nav className="">
        <ul className="flex">
          <li className="ml-2 underline">
            <RouterLink to="/auth/forgot-password">
              Forgot your Password?
            </RouterLink>
          </li>
        </ul>        
      </nav>
        
    </header>);
}

export default Header;