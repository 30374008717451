import { ArrowRightIcon } from '@heroicons/react/solid';

import { redirect, Form } from 'react-router-dom';
import {
    QueryClient,
} from 'react-query';

import { 
    loginForAuthToken,
} from 'api/auth/auth';
import { Helmet } from 'react-helmet';

export const action = (queryClient: QueryClient) => 
async({ request } : any) => {
    const formData = await request.formData();
    const loginInfo = Object.fromEntries(formData);
    await loginForAuthToken({
        username: loginInfo.username,
        password: loginInfo.password,
        client_id: "guyulgang-web-client",
        client_secret: "thi is a secret",
        grant_type: "password",
    }).then((response) => {
        window.localStorage.setItem(
            'access_token',
            response.data?.access_token
        );
    }).catch((error) => {
        console.log(error);
    });
    return redirect('/menu');
};

function Login() {
    return(
    <div className="flex flex-col items-center justify-between text-black">
        <Helmet>
            <title>Login</title>
        </Helmet>
        <img 
        className="mb-10"
        height={128}
        width={128}
        src={require("assets/icon-guyulgang-inverted.png")} 
        alt="Logo - guyulgang"/>
        <Form method="post" id="login-user" className="flex flex-col p-10 bg-white rounded-3xl">
            <h1 className="mb-2 text-2xl font-semibold text-center">Welcome, please login</h1>
            <p className="text-center text-gray-600">
                Our platform is available to authorised users only. If you think you should
                <br/>
                have access then please contact our support officer.
            </p>
            <label className="mt-8 text-gray-500">
                Email address:
                <input 
                name="username"
                type="email"
                className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md" 
                placeholder="e.g admin@wcclp.com.au"/>
            </label>
            <label className="mt-4 text-gray-500">
                Password:
                <input 
                name="password"
                type="password"
                className="w-full px-4 py-2 mt-2 text-lg font-semibold border-2 border-gray-400 rounded-md"/>
            </label>
            <button 
            className="flex items-center justify-center w-full px-4 py-2 mt-10 text-xl text-center text-white bg-gray-800 border-2 border-black rounded-lg">
                <span className="mr-2">Let's go</span>
                <ArrowRightIcon height={24} width={24} />
            </button>
        </Form>
    </div>);
}

export default Login;