import {
    Outlet
} from "react-router-dom";

import Header from "components/app-header";
import Footer from "components/app-footer";

/**
 * This provides a wrapper page for the backend
 * 
 * The routes are defined in the index page, where all pages are 
 * imported and wired up. The aim of this is to provide an overarching
 * template for the authentication pages.
 * 
 * Refer to the use of <Outlet> in the react-router documentation.
 * 
 * @returns {JSX.Element}
 */
function Authentication() {
    return(
        <div className="flex flex-col w-screen h-screen overflow-hidden">
            <Header/>
            <main className="flex-1 h-full bg-white">
                <Outlet />
            </main>
            <Footer/>
        </div>
    );
}

export default Authentication;