/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * guyulgang-api
 * 
        This project provides a reference Python API built using FastAPI, the 
        aim of the project is:

        - To maintain a good know source of habits
        - Demonstrate how applications are meant to be put together at Anomaly
        - Democratize design of robust API    
    
 * OpenAPI spec version: 0.16.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CategoryResponse,
  HTTPValidationError,
  GetCategoriesWithLimitsParams,
  CategoryRequest,
  GetCategoriesParams,
  WordTypeResponse,
  GetWordTypeWithLimitsParams,
  WordTypeRequest,
  GetWordTypeParams,
  WordResponse,
  GetWordsWithLimitsParams,
  WordRequest,
  WordSummaryResponse,
  GetWordsParams,
  WordChangeLogResponse,
  FileUploadResponse,
  FileUploadRequest,
  LanguageResponse,
  LanguageRequest,
  LanguagePublishResponse
} from '.././models'


/**
 * @summary Query categories between limits
 */
export const getCategoriesWithLimits = (
    languageId: string,
    params?: GetCategoriesWithLimitsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryResponse[]>> => {
    return axios.get(
      `/language/${languageId}/category`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetCategoriesWithLimitsQueryKey = (languageId: string,
    params?: GetCategoriesWithLimitsParams,) => [`/language/${languageId}/category`, ...(params ? [params]: [])] as const;
  

    
export const getGetCategoriesWithLimitsQueryOptions = <TData = Awaited<ReturnType<typeof getCategoriesWithLimits>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    params?: GetCategoriesWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoriesWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getCategoriesWithLimits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesWithLimitsQueryKey(languageId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoriesWithLimits>>> = ({ signal }) => getCategoriesWithLimits(languageId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId), ...queryOptions}}

export type GetCategoriesWithLimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getCategoriesWithLimits>>>
export type GetCategoriesWithLimitsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Query categories between limits
 */
export const useGetCategoriesWithLimits = <TData = Awaited<ReturnType<typeof getCategoriesWithLimits>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    params?: GetCategoriesWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoriesWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCategoriesWithLimitsQueryOptions(languageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Creates a new user based on
 * @summary Create a new user
 */
export const createCategory = (
    languageId: string,
    categoryRequest: CategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryResponse>> => {
    return axios.post(
      `/language/${languageId}/category`,
      categoryRequest,options
    );
  }



export const getCreateCategoryMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCategory>>, TError,{languageId: string;data: CategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createCategory>>, TError,{languageId: string;data: CategoryRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCategory>>, {languageId: string;data: CategoryRequest}> = (props) => {
          const {languageId,data} = props ?? {};

          return  createCategory(languageId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof createCategory>>>
    export type CreateCategoryMutationBody = CategoryRequest
    export type CreateCategoryMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create a new user
 */
export const useCreateCategory = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCategory>>, TError,{languageId: string;data: CategoryRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateCategoryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all categories
 */
export const getCategories = (
    languageId: string,
    params?: GetCategoriesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryResponse[]>> => {
    return axios.get(
      `/language/${languageId}/category/infinite`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetCategoriesQueryKey = (languageId: string,
    params?: GetCategoriesParams,) => [`/language/${languageId}/category/infinite`, ...(params ? [params]: [])] as const;
  

    
export const getGetCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getCategories>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    params?: GetCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesQueryKey(languageId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategories>>> = ({ signal }) => getCategories(languageId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId), ...queryOptions}}

export type GetCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCategories>>>
export type GetCategoriesQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get all categories
 */
export const useGetCategories = <TData = Awaited<ReturnType<typeof getCategories>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    params?: GetCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategories>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCategoriesQueryOptions(languageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Get a user by their id
 * @summary Get category by id
 */
export const getCategoryById = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryResponse>> => {
    return axios.get(
      `/language/${languageId}/category/${id}`,options
    );
  }


export const getGetCategoryByIdQueryKey = (languageId: string,
    id: string,) => [`/language/${languageId}/category/${id}`] as const;
  

    
export const getGetCategoryByIdQueryOptions = <TData = Awaited<ReturnType<typeof getCategoryById>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoryById>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getCategoryById>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoryByIdQueryKey(languageId,id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoryById>>> = ({ signal }) => getCategoryById(languageId,id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId && id), ...queryOptions}}

export type GetCategoryByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCategoryById>>>
export type GetCategoryByIdQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get category by id
 */
export const useGetCategoryById = <TData = Awaited<ReturnType<typeof getCategoryById>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoryById>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCategoryByIdQueryOptions(languageId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Delete a user from the database

The endpoint will look to see if the user exists, and if so
will attempt to delete the user from the database and
return a 204 response. If the user does not exist, a 404
 * @summary Delete a particular user
 */
export const deleteCategory = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/language/${languageId}/category/${id}`,options
    );
  }



export const getDeleteCategoryMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCategory>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCategory>>, TError,{languageId: string;id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCategory>>, {languageId: string;id: string}> = (props) => {
          const {languageId,id} = props ?? {};

          return  deleteCategory(languageId,id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCategory>>>
    
    export type DeleteCategoryMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete a particular user
 */
export const useDeleteCategory = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCategory>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteCategoryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a particular user
 */
export const updateCategory = (
    languageId: string,
    id: string,
    categoryRequest: CategoryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/language/${languageId}/category/${id}`,
      categoryRequest,options
    );
  }



export const getUpdateCategoryMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategory>>, TError,{languageId: string;id: string;data: CategoryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCategory>>, TError,{languageId: string;id: string;data: CategoryRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCategory>>, {languageId: string;id: string;data: CategoryRequest}> = (props) => {
          const {languageId,id,data} = props ?? {};

          return  updateCategory(languageId,id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof updateCategory>>>
    export type UpdateCategoryMutationBody = CategoryRequest
    export type UpdateCategoryMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update a particular user
 */
export const useUpdateCategory = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategory>>, TError,{languageId: string;id: string;data: CategoryRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateCategoryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Query word_types between limits
 */
export const getWordTypeWithLimits = (
    languageId: string,
    params?: GetWordTypeWithLimitsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordTypeResponse[]>> => {
    return axios.get(
      `/language/${languageId}/word-type`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetWordTypeWithLimitsQueryKey = (languageId: string,
    params?: GetWordTypeWithLimitsParams,) => [`/language/${languageId}/word-type`, ...(params ? [params]: [])] as const;
  

    
export const getGetWordTypeWithLimitsQueryOptions = <TData = Awaited<ReturnType<typeof getWordTypeWithLimits>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    params?: GetWordTypeWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordTypeWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWordTypeWithLimits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordTypeWithLimitsQueryKey(languageId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordTypeWithLimits>>> = ({ signal }) => getWordTypeWithLimits(languageId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId), ...queryOptions}}

export type GetWordTypeWithLimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getWordTypeWithLimits>>>
export type GetWordTypeWithLimitsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Query word_types between limits
 */
export const useGetWordTypeWithLimits = <TData = Awaited<ReturnType<typeof getWordTypeWithLimits>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    params?: GetWordTypeWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordTypeWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordTypeWithLimitsQueryOptions(languageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Creates a new word_type based on
 * @summary Create a new word_type
 */
export const createWordType = (
    languageId: string,
    wordTypeRequest: WordTypeRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordTypeResponse>> => {
    return axios.post(
      `/language/${languageId}/word-type`,
      wordTypeRequest,options
    );
  }



export const getCreateWordTypeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWordType>>, TError,{languageId: string;data: WordTypeRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createWordType>>, TError,{languageId: string;data: WordTypeRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWordType>>, {languageId: string;data: WordTypeRequest}> = (props) => {
          const {languageId,data} = props ?? {};

          return  createWordType(languageId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateWordTypeMutationResult = NonNullable<Awaited<ReturnType<typeof createWordType>>>
    export type CreateWordTypeMutationBody = WordTypeRequest
    export type CreateWordTypeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create a new word_type
 */
export const useCreateWordType = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWordType>>, TError,{languageId: string;data: WordTypeRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateWordTypeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all word_types
 */
export const getWordType = (
    languageId: string,
    params?: GetWordTypeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordTypeResponse[]>> => {
    return axios.get(
      `/language/${languageId}/word-type/infinite`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetWordTypeQueryKey = (languageId: string,
    params?: GetWordTypeParams,) => [`/language/${languageId}/word-type/infinite`, ...(params ? [params]: [])] as const;
  

    
export const getGetWordTypeQueryOptions = <TData = Awaited<ReturnType<typeof getWordType>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    params?: GetWordTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordType>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWordType>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordTypeQueryKey(languageId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordType>>> = ({ signal }) => getWordType(languageId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId), ...queryOptions}}

export type GetWordTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getWordType>>>
export type GetWordTypeQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get all word_types
 */
export const useGetWordType = <TData = Awaited<ReturnType<typeof getWordType>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    params?: GetWordTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordType>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordTypeQueryOptions(languageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Get a word_type by their id
 * @summary Get a particular word_type
 */
export const getWordTypeById = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordTypeResponse>> => {
    return axios.get(
      `/language/${languageId}/word-type/${id}`,options
    );
  }


export const getGetWordTypeByIdQueryKey = (languageId: string,
    id: string,) => [`/language/${languageId}/word-type/${id}`] as const;
  

    
export const getGetWordTypeByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWordTypeById>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordTypeById>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWordTypeById>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordTypeByIdQueryKey(languageId,id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordTypeById>>> = ({ signal }) => getWordTypeById(languageId,id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId && id), ...queryOptions}}

export type GetWordTypeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWordTypeById>>>
export type GetWordTypeByIdQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get a particular word_type
 */
export const useGetWordTypeById = <TData = Awaited<ReturnType<typeof getWordTypeById>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordTypeById>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordTypeByIdQueryOptions(languageId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Delete a word_type from the database

The endpoint will look to see if the word_type exists, and if so
will attempt to delete the word_type from the database and
return a 204 response. If the word_type does not exist, a 404
 * @summary Delete a particular word_type
 */
export const deleteWordType = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/language/${languageId}/word-type/${id}`,options
    );
  }



export const getDeleteWordTypeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWordType>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWordType>>, TError,{languageId: string;id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWordType>>, {languageId: string;id: string}> = (props) => {
          const {languageId,id} = props ?? {};

          return  deleteWordType(languageId,id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteWordTypeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWordType>>>
    
    export type DeleteWordTypeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete a particular word_type
 */
export const useDeleteWordType = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWordType>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteWordTypeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a particular word_type
 */
export const updateWordType = (
    languageId: string,
    id: string,
    wordTypeRequest: WordTypeRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/language/${languageId}/word-type/${id}`,
      wordTypeRequest,options
    );
  }



export const getUpdateWordTypeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWordType>>, TError,{languageId: string;id: string;data: WordTypeRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateWordType>>, TError,{languageId: string;id: string;data: WordTypeRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWordType>>, {languageId: string;id: string;data: WordTypeRequest}> = (props) => {
          const {languageId,id,data} = props ?? {};

          return  updateWordType(languageId,id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateWordTypeMutationResult = NonNullable<Awaited<ReturnType<typeof updateWordType>>>
    export type UpdateWordTypeMutationBody = WordTypeRequest
    export type UpdateWordTypeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update a particular word_type
 */
export const useUpdateWordType = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWordType>>, TError,{languageId: string;id: string;data: WordTypeRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateWordTypeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Query categories between limits
 */
export const getWordsWithLimits = (
    languageId: string,
    params?: GetWordsWithLimitsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordResponse[]>> => {
    return axios.get(
      `/language/${languageId}/word`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetWordsWithLimitsQueryKey = (languageId: string,
    params?: GetWordsWithLimitsParams,) => [`/language/${languageId}/word`, ...(params ? [params]: [])] as const;
  

    
export const getGetWordsWithLimitsQueryOptions = <TData = Awaited<ReturnType<typeof getWordsWithLimits>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    params?: GetWordsWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordsWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWordsWithLimits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsWithLimitsQueryKey(languageId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordsWithLimits>>> = ({ signal }) => getWordsWithLimits(languageId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId), ...queryOptions}}

export type GetWordsWithLimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getWordsWithLimits>>>
export type GetWordsWithLimitsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Query categories between limits
 */
export const useGetWordsWithLimits = <TData = Awaited<ReturnType<typeof getWordsWithLimits>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    params?: GetWordsWithLimitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordsWithLimits>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordsWithLimitsQueryOptions(languageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Creates a new word based on
 * @summary Create a new word
 */
export const createWord = (
    languageId: string,
    wordRequest: WordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordResponse>> => {
    return axios.post(
      `/language/${languageId}/word`,
      wordRequest,options
    );
  }



export const getCreateWordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWord>>, TError,{languageId: string;data: WordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createWord>>, TError,{languageId: string;data: WordRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWord>>, {languageId: string;data: WordRequest}> = (props) => {
          const {languageId,data} = props ?? {};

          return  createWord(languageId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateWordMutationResult = NonNullable<Awaited<ReturnType<typeof createWord>>>
    export type CreateWordMutationBody = WordRequest
    export type CreateWordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create a new word
 */
export const useCreateWord = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWord>>, TError,{languageId: string;data: WordRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateWordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all categories
 */
export const getWords = (
    languageId: string,
    params?: GetWordsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordSummaryResponse[]>> => {
    return axios.get(
      `/language/${languageId}/word/infinite`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetWordsQueryKey = (languageId: string,
    params?: GetWordsParams,) => [`/language/${languageId}/word/infinite`, ...(params ? [params]: [])] as const;
  

    
export const getGetWordsQueryOptions = <TData = Awaited<ReturnType<typeof getWords>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    params?: GetWordsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordsQueryKey(languageId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWords>>> = ({ signal }) => getWords(languageId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId), ...queryOptions}}

export type GetWordsQueryResult = NonNullable<Awaited<ReturnType<typeof getWords>>>
export type GetWordsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get all categories
 */
export const useGetWords = <TData = Awaited<ReturnType<typeof getWords>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    params?: GetWordsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWords>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordsQueryOptions(languageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Get a word by their id
 * @summary Get a particular word
 */
export const getWordById = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordResponse>> => {
    return axios.get(
      `/language/${languageId}/word/${id}`,options
    );
  }


export const getGetWordByIdQueryKey = (languageId: string,
    id: string,) => [`/language/${languageId}/word/${id}`] as const;
  

    
export const getGetWordByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWordById>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordById>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWordById>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordByIdQueryKey(languageId,id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordById>>> = ({ signal }) => getWordById(languageId,id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId && id), ...queryOptions}}

export type GetWordByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWordById>>>
export type GetWordByIdQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get a particular word
 */
export const useGetWordById = <TData = Awaited<ReturnType<typeof getWordById>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordById>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordByIdQueryOptions(languageId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Delete a word from the database

The endpoint will look to see if the word exists, and if so
will attempt to delete the word from the database and
return a 204 response. If the word does not exist, a 404
 * @summary Delete a particular word
 */
export const deleteWord = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/language/${languageId}/word/${id}`,options
    );
  }



export const getDeleteWordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWord>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWord>>, TError,{languageId: string;id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWord>>, {languageId: string;id: string}> = (props) => {
          const {languageId,id} = props ?? {};

          return  deleteWord(languageId,id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteWordMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWord>>>
    
    export type DeleteWordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete a particular word
 */
export const useDeleteWord = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWord>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteWordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a particular word
 */
export const updateWord = (
    languageId: string,
    id: string,
    wordRequest: WordRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/language/${languageId}/word/${id}`,
      wordRequest,options
    );
  }



export const getUpdateWordMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWord>>, TError,{languageId: string;id: string;data: WordRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateWord>>, TError,{languageId: string;id: string;data: WordRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWord>>, {languageId: string;id: string;data: WordRequest}> = (props) => {
          const {languageId,id,data} = props ?? {};

          return  updateWord(languageId,id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateWordMutationResult = NonNullable<Awaited<ReturnType<typeof updateWord>>>
    export type UpdateWordMutationBody = WordRequest
    export type UpdateWordMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update a particular word
 */
export const useUpdateWord = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWord>>, TError,{languageId: string;id: string;data: WordRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateWordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Get a word by their id
 * @summary Get logs for a word
 */
export const getWordChangeLogById = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WordChangeLogResponse[]>> => {
    return axios.get(
      `/language/${languageId}/word/${id}/logs`,options
    );
  }


export const getGetWordChangeLogByIdQueryKey = (languageId: string,
    id: string,) => [`/language/${languageId}/word/${id}/logs`] as const;
  

    
export const getGetWordChangeLogByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWordChangeLogById>>, TError = AxiosError<HTTPValidationError>>(languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordChangeLogById>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getWordChangeLogById>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWordChangeLogByIdQueryKey(languageId,id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWordChangeLogById>>> = ({ signal }) => getWordChangeLogById(languageId,id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(languageId && id), ...queryOptions}}

export type GetWordChangeLogByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWordChangeLogById>>>
export type GetWordChangeLogByIdQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get logs for a word
 */
export const useGetWordChangeLogById = <TData = Awaited<ReturnType<typeof getWordChangeLogById>>, TError = AxiosError<HTTPValidationError>>(
 languageId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWordChangeLogById>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWordChangeLogByIdQueryOptions(languageId,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Get a new upload url for the audio file
 */
export const createWordAudioFile = (
    languageId: string,
    id: string,
    fileUploadRequest: FileUploadRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileUploadResponse>> => {
    return axios.post(
      `/language/${languageId}/word/${id}/audio`,
      fileUploadRequest,options
    );
  }



export const getCreateWordAudioFileMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWordAudioFile>>, TError,{languageId: string;id: string;data: FileUploadRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createWordAudioFile>>, TError,{languageId: string;id: string;data: FileUploadRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWordAudioFile>>, {languageId: string;id: string;data: FileUploadRequest}> = (props) => {
          const {languageId,id,data} = props ?? {};

          return  createWordAudioFile(languageId,id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateWordAudioFileMutationResult = NonNullable<Awaited<ReturnType<typeof createWordAudioFile>>>
    export type CreateWordAudioFileMutationBody = FileUploadRequest
    export type CreateWordAudioFileMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Get a new upload url for the audio file
 */
export const useCreateWordAudioFile = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWordAudioFile>>, TError,{languageId: string;id: string;data: FileUploadRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateWordAudioFileMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Drop the audio file
 */
export const deleteWordAudio = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileUploadResponse>> => {
    return axios.delete(
      `/language/${languageId}/word/${id}/audio`,options
    );
  }



export const getDeleteWordAudioMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWordAudio>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWordAudio>>, TError,{languageId: string;id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWordAudio>>, {languageId: string;id: string}> = (props) => {
          const {languageId,id} = props ?? {};

          return  deleteWordAudio(languageId,id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteWordAudioMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWordAudio>>>
    
    export type DeleteWordAudioMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Drop the audio file
 */
export const useDeleteWordAudio = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWordAudio>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteWordAudioMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a new upload url for the image file
 */
export const createWordImageFile = (
    languageId: string,
    id: string,
    fileUploadRequest: FileUploadRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileUploadResponse>> => {
    return axios.post(
      `/language/${languageId}/word/${id}/image`,
      fileUploadRequest,options
    );
  }



export const getCreateWordImageFileMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWordImageFile>>, TError,{languageId: string;id: string;data: FileUploadRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createWordImageFile>>, TError,{languageId: string;id: string;data: FileUploadRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWordImageFile>>, {languageId: string;id: string;data: FileUploadRequest}> = (props) => {
          const {languageId,id,data} = props ?? {};

          return  createWordImageFile(languageId,id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateWordImageFileMutationResult = NonNullable<Awaited<ReturnType<typeof createWordImageFile>>>
    export type CreateWordImageFileMutationBody = FileUploadRequest
    export type CreateWordImageFileMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Get a new upload url for the image file
 */
export const useCreateWordImageFile = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWordImageFile>>, TError,{languageId: string;id: string;data: FileUploadRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateWordImageFileMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Drop the image file for a word
 */
export const deleteWordImage = (
    languageId: string,
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/language/${languageId}/word/${id}/image`,options
    );
  }



export const getDeleteWordImageMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWordImage>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWordImage>>, TError,{languageId: string;id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWordImage>>, {languageId: string;id: string}> = (props) => {
          const {languageId,id} = props ?? {};

          return  deleteWordImage(languageId,id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteWordImageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWordImage>>>
    
    export type DeleteWordImageMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Drop the image file for a word
 */
export const useDeleteWordImage = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWordImage>>, TError,{languageId: string;id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteWordImageMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Add a category to a word
 */
export const assignWordToCategory = (
    languageId: string,
    id: string,
    categoryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/language/${languageId}/word/${id}/category/${categoryId}`,undefined,options
    );
  }



export const getAssignWordToCategoryMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWordToCategory>>, TError,{languageId: string;id: string;categoryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof assignWordToCategory>>, TError,{languageId: string;id: string;categoryId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignWordToCategory>>, {languageId: string;id: string;categoryId: string}> = (props) => {
          const {languageId,id,categoryId} = props ?? {};

          return  assignWordToCategory(languageId,id,categoryId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AssignWordToCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof assignWordToCategory>>>
    
    export type AssignWordToCategoryMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add a category to a word
 */
export const useAssignWordToCategory = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWordToCategory>>, TError,{languageId: string;id: string;categoryId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getAssignWordToCategoryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Add a category to a word
 */
export const removeWordFromCategory = (
    languageId: string,
    id: string,
    categoryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/language/${languageId}/word/${id}/category/${categoryId}`,options
    );
  }



export const getRemoveWordFromCategoryMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeWordFromCategory>>, TError,{languageId: string;id: string;categoryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeWordFromCategory>>, TError,{languageId: string;id: string;categoryId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeWordFromCategory>>, {languageId: string;id: string;categoryId: string}> = (props) => {
          const {languageId,id,categoryId} = props ?? {};

          return  removeWordFromCategory(languageId,id,categoryId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveWordFromCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof removeWordFromCategory>>>
    
    export type RemoveWordFromCategoryMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add a category to a word
 */
export const useRemoveWordFromCategory = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeWordFromCategory>>, TError,{languageId: string;id: string;categoryId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveWordFromCategoryMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Add a category to a word
 */
export const assignWordToType = (
    languageId: string,
    id: string,
    wordTypeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/language/${languageId}/word/${id}/word-type/${wordTypeId}`,undefined,options
    );
  }



export const getAssignWordToTypeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWordToType>>, TError,{languageId: string;id: string;wordTypeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof assignWordToType>>, TError,{languageId: string;id: string;wordTypeId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignWordToType>>, {languageId: string;id: string;wordTypeId: string}> = (props) => {
          const {languageId,id,wordTypeId} = props ?? {};

          return  assignWordToType(languageId,id,wordTypeId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AssignWordToTypeMutationResult = NonNullable<Awaited<ReturnType<typeof assignWordToType>>>
    
    export type AssignWordToTypeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add a category to a word
 */
export const useAssignWordToType = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWordToType>>, TError,{languageId: string;id: string;wordTypeId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getAssignWordToTypeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all languages
 */
export const getAllLanguages = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LanguageResponse[]>> => {
    return axios.get(
      `/language`,options
    );
  }


export const getGetAllLanguagesQueryKey = () => [`/language`] as const;
  

    
export const getGetAllLanguagesQueryOptions = <TData = Awaited<ReturnType<typeof getAllLanguages>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllLanguages>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getAllLanguages>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllLanguagesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllLanguages>>> = ({ signal }) => getAllLanguages({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAllLanguagesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllLanguages>>>
export type GetAllLanguagesQueryError = AxiosError<unknown>

/**
 * @summary Get all languages
 */
export const useGetAllLanguages = <TData = Awaited<ReturnType<typeof getAllLanguages>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllLanguages>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllLanguagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Creates a new language based on
 * @summary Create a new language
 */
export const createLanguage = (
    languageRequest: LanguageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LanguageResponse>> => {
    return axios.post(
      `/language`,
      languageRequest,options
    );
  }



export const getCreateLanguageMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLanguage>>, TError,{data: LanguageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createLanguage>>, TError,{data: LanguageRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLanguage>>, {data: LanguageRequest}> = (props) => {
          const {data} = props ?? {};

          return  createLanguage(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateLanguageMutationResult = NonNullable<Awaited<ReturnType<typeof createLanguage>>>
    export type CreateLanguageMutationBody = LanguageRequest
    export type CreateLanguageMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create a new language
 */
export const useCreateLanguage = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLanguage>>, TError,{data: LanguageRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateLanguageMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * Get a language by their id
 * @summary Get a particular language
 */
export const getLanguageById = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LanguageResponse>> => {
    return axios.get(
      `/language/${id}`,options
    );
  }


export const getGetLanguageByIdQueryKey = (id: string,) => [`/language/${id}`] as const;
  

    
export const getGetLanguageByIdQueryOptions = <TData = Awaited<ReturnType<typeof getLanguageById>>, TError = AxiosError<HTTPValidationError>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLanguageById>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getLanguageById>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLanguageByIdQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLanguageById>>> = ({ signal }) => getLanguageById(id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetLanguageByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getLanguageById>>>
export type GetLanguageByIdQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get a particular language
 */
export const useGetLanguageById = <TData = Awaited<ReturnType<typeof getLanguageById>>, TError = AxiosError<HTTPValidationError>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLanguageById>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLanguageByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * Delete a language from the database

The endpoint will look to see if the language exists, and if so
will attempt to delete the language from the database and
return a 204 response. If the language does not exist, a 404
 * @summary Delete a particular language
 */
export const deleteLanguage = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/language/${id}`,options
    );
  }



export const getDeleteLanguageMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLanguage>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteLanguage>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLanguage>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteLanguage(id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteLanguageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLanguage>>>
    
    export type DeleteLanguageMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete a particular language
 */
export const useDeleteLanguage = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLanguage>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteLanguageMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Update a particular language
 */
export const updateLanguage = (
    id: string,
    languageRequest: LanguageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/language/${id}`,
      languageRequest,options
    );
  }



export const getUpdateLanguageMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLanguage>>, TError,{id: string;data: LanguageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateLanguage>>, TError,{id: string;data: LanguageRequest}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLanguage>>, {id: string;data: LanguageRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  updateLanguage(id,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateLanguageMutationResult = NonNullable<Awaited<ReturnType<typeof updateLanguage>>>
    export type UpdateLanguageMutationBody = LanguageRequest
    export type UpdateLanguageMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update a particular language
 */
export const useUpdateLanguage = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLanguage>>, TError,{id: string;data: LanguageRequest}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateLanguageMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all categories
 */
export const getAllPublications = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LanguagePublishResponse[]>> => {
    return axios.get(
      `/language/${id}/publications`,options
    );
  }


export const getGetAllPublicationsQueryKey = (id: string,) => [`/language/${id}/publications`] as const;
  

    
export const getGetAllPublicationsQueryOptions = <TData = Awaited<ReturnType<typeof getAllPublications>>, TError = AxiosError<HTTPValidationError>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllPublications>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getAllPublications>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPublicationsQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllPublications>>> = ({ signal }) => getAllPublications(id, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetAllPublicationsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllPublications>>>
export type GetAllPublicationsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get all categories
 */
export const useGetAllPublications = <TData = Awaited<ReturnType<typeof getAllPublications>>, TError = AxiosError<HTTPValidationError>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllPublications>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllPublicationsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Get all categories
 */
export const publishLanguageVersion = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<LanguagePublishResponse>> => {
    return axios.post(
      `/language/${id}/publications`,undefined,options
    );
  }



export const getPublishLanguageVersionMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publishLanguageVersion>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof publishLanguageVersion>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publishLanguageVersion>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  publishLanguageVersion(id,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PublishLanguageVersionMutationResult = NonNullable<Awaited<ReturnType<typeof publishLanguageVersion>>>
    
    export type PublishLanguageVersionMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Get all categories
 */
export const usePublishLanguageVersion = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publishLanguageVersion>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPublishLanguageVersionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    