import { redirect } from 'react-router-dom';
import { QueryClient } from 'react-query';

import { 
    deleteWordType, 
    getGetWordTypeWithLimitsQueryKey 
} from 'api/language/language';

export const action =
    (queryClient : QueryClient) =>
    async({ params }: any)  => {
    await deleteWordType(params.id, params.categoryId);
    queryClient.invalidateQueries(getGetWordTypeWithLimitsQueryKey(params.id));
    return redirect('../');
}