import { useParams, Outlet } from 'react-router-dom';

import WordNavigation from './navigation';

function WordsIndex() {

  return (
    <div className="grid grid-cols-[25%,50%,25%] bg-white h-full text-black">
      <WordNavigation />
      <Outlet/>
    </div>
  );
}

export default WordsIndex;