
import { useParams } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import {
  useGetAllPublications,
  useGetLanguageById,
  publishLanguageVersion,
} from 'api/language/language';

import { CheckIcon, ExclamationCircleIcon, UserIcon } from '@heroicons/react/solid'

import {
  parseISO,
  format
} from 'date-fns';
import { use } from 'i18next';


export default function WordHistory() {

  const queryClient = useQueryClient();

  const { id } :any = useParams();

  const {
    data: language
  } = useGetLanguageById(id);

  const { 
    data: publications,
  } = useGetAllPublications(id);

  const handlePublicationAction = () => {
    publishLanguageVersion(id);
  }


  return (
    <div className="flex flex-col p-4 border-l border-gray-200">
      <h2 className="text-xl font-bold">Publishing Timeline</h2>
      {publications?.data.length === 0 && (
      <div className="p-8 text-center ">No publications yet</div>
      )}
      <h1>Story</h1>
      <ul className="mb-8">
        {publications?.data.map((publication: any) => (
          <li key={publication.id} className="flex items-center justify-between py-2">
            <div className="flex">
              <div className="flex items-center justify-center w-8 h-8 mr-4 text-white bg-pink-500 rounded-full">
                <UserIcon className="w-5 h-5" />
              </div>
              <div className="flex flex-col">
                <span className="font-bold">{publication.createdByFullName}</span>
                <span className="text-sm text-gray-500">{format(parseISO(publication.createdAt), "dd MM yyyy")}</span>
              </div>
            </div>
            {publication.publicationDate != null ? (
            <div className="flex items-center justify-center w-8 h-8 text-white bg-pink-500 rounded-full">
              <CheckIcon className="w-5 h-5" />
            </div>) : (            
            <div className="flex items-center justify-center w-8 h-8 text-white bg-yellow-500 rounded-full">
              <ExclamationCircleIcon className="w-5 h-5" />
            </div>)}
            </li>
        ))}

      </ul>

      <button 
      onClick={handlePublicationAction}
      className="py-2 text-white bg-pink-500 border border-pink-700 rounded-md">
        Publish New Version
        </button>
    </div>
  )
}
