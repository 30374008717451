import {
    useParams,
    Link,
    Form,
} from 'react-router-dom';

import {
    QueryClient,
} from 'react-query';

import {
    WordTypeResponse,
} from 'api/models';

import {
    useGetWordTypeWithLimits,
    getGetWordTypeWithLimitsQueryKey,
} from 'api/language/language';

/**
 * Loader that the router uses to get content, this
 * returns an async function that it can use.
 * 
 * react-query takes the key as an array, which would
 * represent the collection as well as individual items.
 * 
 * getGetUsersWithLimitsQueryKey is generated by orval
 * which provides a construct similar to what's reference
 * in the documentation.
 * 
 * const contactListQuery = (q) => ({
*    queryKey: ["contacts", "list", q ?? "all"],
*    queryFn: () => getContacts(q),
*  })
 * 
 * 
 * @param queryClient 
 * @returns function that returns a promise
 */
export const loader =
    (queryClient: QueryClient) =>
        async (params: any) => {
            return (
                queryClient.getQueriesData(
                    getGetWordTypeWithLimitsQueryKey(params.languageId)
                ) ?? (await queryClient.fetchQuery(
                    getGetWordTypeWithLimitsQueryKey(params.languageId)
                ))
            );
        }

function ListCategories() {

    const { id }: any = useParams();

    const {
        data: wordTypes,
        refetch
    } = useGetWordTypeWithLimits(id, {
        offset: 0,
        limit: 100
    });

    return (
        <>
            <div className="flex items-center justify-between">
                <h1 className="mb-4 text-4xl font-bold">Word Types</h1>
                <Link className="p-4 text-white bg-green-700 rounded-sm" to="new">New Word Type</Link>
            </div>
            {wordTypes?.data.length === 0 && (
                <div className="py-10 text-center">
                    <p className="text-2xl text-gray-500">No word types found. Please add one</p>
                </div>)}
            <ul className="max-w-2xl mx-auto overflow-y-auto text-gray-700">
                {wordTypes?.data.map((wordType: WordTypeResponse) => (
                    <li key={wordType.id} className="flex items-center justify-between mb-4">
                        <p className="font-semibold">
                            {wordType.name}
                        </p>

                        <nav className="grid grid-cols-2 text-center">
                            <Form
                                method="post"
                                action={`${wordType.id}/destroy`}
                                className="py-2 bg-red-100 border-r-2 border-gray-100 rounded-b-r-lg"
                                onSubmit={(event) => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (!confirm("Please confirm you want to delete this record.")) {
                                        event.preventDefault();
                                    }
                                }}>
                                <button
                                    className="text-red-400 hover:underline hover:text-red-600"
                                    type="submit">
                                    Delete
                                </button>
                            </Form>

                            <Link
                                className="rounded-md ml-8 bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
                                to={`${wordType.id}/edit`}>
                                Edit
                            </Link>
                        </nav>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default ListCategories;