import {
    QueryClient,
} from 'react-query';
import { redirect } from 'react-router';

import {
    Link as RouterLink,
  } from 'react-router-dom';
  
export const loader =
    (queryClient: QueryClient) =>
        async ({ request, params }: any) => {
            window.localStorage.removeItem('access_token');
            return {};
        };


function Logout() {
    return(
    <div>
        <RouterLink to="/auth/login" className="text-2xl underline">Return to login</RouterLink>
    </div>)}

export default Logout;