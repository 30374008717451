import { redirect } from 'react-router-dom';
import { QueryClient } from 'react-query';

import { deleteCategory } from 'api/language/language';
import { getGetCategoriesWithLimitsQueryKey } from 'api/language/language';

export const action =
    (queryClient : QueryClient) =>
    async({ params }: any)  => {
    await deleteCategory(params.id, params.categoryId);
    queryClient.invalidateQueries(getGetCategoriesWithLimitsQueryKey(params.id));
    return redirect('../');
}